import React from "react";
import Dialog from "./Dialog";
import { Dropdown } from "semantic-ui-react";
import { parseValidNumber } from "./cupla";

export default class LockerDialog extends Dialog {
    locker = null;
    lockerStatus = null;
    productOptions = [];
    productID = 0;
    count = 0;

    constructor(locker, lockerStatus) {
        super();
        console.log(lockerStatus);
        this.locker = locker;
        this.lockerStatus = lockerStatus;
    }

    async onShow() {
        const products = await window.eng.getSiteProducts(this.locker.site);
        var productOptions = [{ key: "", text: "", value: 0 }];
        for (let product of products) {
            productOptions.push({
                key: product.id,
                text: window.eng.getProductName(product),
                value: product.id
            });
        }
        this.productOptions = productOptions;
        this.productID = this.lockerStatus.product
            ? this.lockerStatus.product.id
            : 0;

        this.count = this.lockerStatus.count;

        this.setTitle("LOKERO " + this.locker.number);

        this.setBody(
            <div className="FlexVertical">
                <div className="PropertyRow">
                    <div>Tuote:</div>
                    <Dropdown
                        placeholder="Valitse tuote"
                        selection
                        options={this.productOptions}
                        defaultValue={this.productID}
                        onChange={(ev, data) => (this.productID = data.value)}
                    />
                </div>
                <div className="PropertyRow">
                    <div>Kpl:</div>
                    <input
                        className="form-control"
                        onChange={e =>
                            (this.count = parseValidNumber(e.target.value))
                        }
                        type="number"
                        defaultValue={this.count}
                    />
                </div>
            </div>
        );

        this.setFooter(
            <>
                {this.lockerStatus.count ? (
                    <button
                        className="ui button"
                        onClick={() => this.onClickButton(Dialog.NO)}
                    >
                        OTA LOKEROSTA
                    </button>
                ) : null}
                <div style={{ flex: "1 0 auto" }} />
                <button
                    className="ui button"
                    onClick={() => this.onClickButton(Dialog.CANCEL)}
                >
                    PERUUTA
                </button>
                <button
                    className="ui button RoolsGreenBG"
                    onClick={() => this.onClickButton(Dialog.YES)}
                >
                    TALLENNA
                </button>
            </>
        );
    }

    async onClickButton(result) {
        if (result === Dialog.YES) {
            let originalProductID = this.lockerStatus.product
                ? this.lockerStatus.product.id
                : 0;
            if (
                this.productID !== originalProductID ||
                this.count !== this.lockerStatus.count
            ) {
                await window.eng.setLockerStatus(
                    this.locker.site,
                    this.locker.id,
                    this.productID,
                    this.count
                );
            }
        }
        this.finish(result);
    }
}
