import React from "react";
import SawView from "./SawView";

export default class SawsView extends React.Component {
    render() {
        return (
            <div className="FlexHorizontal" style={this.props.style}>
                {this.props.sawStatuses
                    ? this.props.sawStatuses.map(sawStatus => (
                          <SawView
                              key={sawStatus.saw.id}
                              sawStatus={sawStatus}
                              site={this.props.site}
                              vehicle={this.props.vehicle}
                          />
                      ))
                    : null}
            </div>
        );
    }
}
