import React from "react";
import { Router, Route } from "react-router-dom";
import history from "./history";
import LoginPage from "./LoginPage";
import CheckinPage from "./CheckinPage";
import SitesPage from "./SitesPage";
import UsersPage from "./UsersPage";
import FieldPage from "./FieldPage";
import FieldMapPage from "./FieldMapPage";
import VehiclesPage from "./VehiclesPage";
import ProductsPage from "./ProductsPage";
import Toolbar from "./Toolbar";
import { dlog, parseValidNumber } from "./cupla";
import { AppEngine } from "./AppEngine";

export default class App extends React.Component {
    page = null;
    state = {
        dialogs: [],
        isCheckin: false
    };

    constructor(props) {
        super(props);
        window.app = this;
        window.eng = new AppEngine();

        dlog(
            "Display " +
                window.innerWidth +
                "x" +
                window.innerHeight +
                " mobile=" +
                this.isMobile() +
                " touch=" +
                this.isTouch()
        );
    }

    render() {
        return (
            <Router history={history}>
                {this.state.isCheckin ? (
                    <CheckinPage
                        onCheckin={site => this.onCheckin(site)}
                        onLogOut={() => this.onLogOut()}
                    />
                ) : (
                    <div className="FlexVertical" style={{ height: "100%" }}>
                        <Toolbar onLogOut={() => this.onLogOut()} />
                        <Route
                            exact
                            path="/"
                            render={() =>
                                window.eng.user ? (
                                    history.push("/sites")
                                ) : (
                                    <LoginPage
                                        onFormSubmit={user =>
                                            this.onLogin(user)
                                        }
                                    />
                                )
                            }
                        />
                        <Route
                            exact
                            path="/sites"
                            render={() => <SitesPage />}
                        />
                        <Route
                            exact
                            path="/sites/:id"
                            render={props => <FieldPage {...props} />}
                        />
                        <Route
                            exact
                            path="/sites/:id/map/edit"
                            render={props =>
                                this.renderPage(FieldMapPage, {
                                    edit: true,
                                    ...props
                                })
                            }
                        />
                        <Route
                            exact
                            path="/sites/:id/map"
                            render={props =>
                                this.renderPage(FieldMapPage, props)
                            }
                        />
                        <Route
                            exact
                            path="/users/"
                            render={() => <UsersPage />}
                        />
                        <Route
                            exact
                            path="/users/:id"
                            render={props => <UsersPage {...props} />}
                        />
                        <Route
                            exact
                            path="/vehicles/:id"
                            render={props => <VehiclesPage {...props} />}
                        />
                        <Route
                            exact
                            path="/products/:id"
                            render={props => <ProductsPage {...props} />}
                        />
                    </div>
                )}

                {this.state.dialogs.map(dlg => dlg.modalElement)}
            </Router>
        );
    }

    componentDidMount() {
        document.body.onkeyup = event => {
            if (this.page && this.page.onAppKeyUp) {
                this.page.onAppKeyUp(event.which || event.keyCode);
            }
        };
    }

    renderPage(clazz, props) {
        return React.createElement(
            clazz,
            {
                ...props,
                ref: e => {
                    this.page = e;
                    window.page = e;
                }
            },
            null
        );
    }

    onLogin(user) {
        if (user.features.includes("D")) {
            this.setState({ isCheckin: true });
        }
        history.push("/");
        this.forceUpdate();
    }

    addDialog(dlg) {
        let dialogs = this.state.dialogs;
        dialogs.push(dlg);
        this.setDialogs(dialogs);
    }

    removeDialog(dlg) {
        let i = this.state.dialogs.indexOf(dlg);
        if (i >= 0) {
            let dialogs = this.state.dialogs;
            dialogs.splice(i, 1);
            this.setDialogs(dialogs);
        }
    }

    setDialogs(dialogs) {
        this.setState({ dialogs: dialogs });
    }

    async onCheckin(site) {
        this.setState({ isCheckin: false });
        await window.eng.driverBegWork();
        history.push("/sites/" + parseValidNumber(site.id));
    }

    async onLogOut() {
        await window.eng.logout();
        history.push("/");
        window.location.reload();
    }

    /** Tells if running in mobile device (small vertical display) */
    isMobile() {
        return window.innerWidth <= 800;
    }

    /** Tells if running in touch based device (no mouse) */
    isTouch() {
        return "ontouchstart" in window;
    }
}
