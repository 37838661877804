import { getElementRect, dlog, currentTime } from "./cupla";

/** Our touch event implementation
    NOTE: Here first touch is always first touch and will not change even if user makes touch up for that.
*/
export class MotionEvent {
    static DOWN = 1;
    static UP = 2;
    static MOVE = 3;
    static CANCEL = 4;
    static OTHER_DOWN = 5;
    static OTHER_UP = 6;

    time = 0;
    action = 0;

    /** First touch (or zero) */
    id = 0;
    x = 0;
    y = 0;

    /** Second touch (or zero) */
    id1 = 0;
    x1 = 0;
    y1 = 0;

    /** Creates new event */
    static create(action, x, y) {
        let e = new MotionEvent();
        e.time = currentTime();
        e.action = action;
        e.id = 1;
        e.x = x;
        e.y = y;
        return e;
    }

    /** Creates new copy of this event */
    copy() {
        let e = new MotionEvent();
        e.time = this.time;
        e.action = this.action;
        e.id = this.id;
        e.x = this.x;
        e.y = this.y;
        e.id1 = this.id1;
        e.x1 = this.x1;
        e.y1 = this.y1;
        return e;
    }

    /** Tells if any touch is down */
    isDown() {
        return this.id || this.id1 ? true : false;
    }

    /** Tells how many touches are down */
    getTouchCount() {
        if (this.id1) return 2;
        if (this.id) return 1;
        return 0;
    }
}

/** Multi-purpose touch handler */
export class TouchHandler {
    elem = null;
    event = new MotionEvent();
    timeOffset = 0;
    eventsWhenUp = false;

    /** Constructor */
    constructor(elem, gestureDetector, eventsWhenUp, captureWhenDown) {
        this.elem = elem;
        this.eventsWhenUp = eventsWhenUp ? true : false;

        elem.style.touchAction = "none";

        // Generic touch handlers (adapter from platform support below)
        let touchdown = (ev, p0, p1) => {
            if (captureWhenDown && elem.setPointerCapture) {
                elem.setPointerCapture(ev.pointerId);
            }
            this.updateEvent(ev.timeStamp, MotionEvent.DOWN, p0, p1);
            if (this.touch()) ev.stopPropagation();
        };
        let touchmove = (ev, p0, p1) => {
            if (this.event.isDown() || this.eventsWhenUp) {
                this.updateEvent(ev.timeStamp, MotionEvent.MOVE, p0, p1);
                if (this.touch()) ev.stopPropagation();
            }
        };
        let touchup = (ev, p0, p1) => {
            if (this.event.isDown()) {
                if (captureWhenDown && elem.releasePointerCapture) {
                    elem.releasePointerCapture(ev.pointerId);
                }
                if (
                    ev.tch !== MotionEvent.CANCEL &&
                    this.event.x >= 0 &&
                    this.event.x < elem.offsetWidth &&
                    this.event.y >= 0 &&
                    this.event.y < elem.offsetHeight
                ) {
                    this.updateEvent(ev.timeStamp, MotionEvent.UP, p0, p1);
                } else {
                    this.updateEvent(ev.timeStamp, MotionEvent.CANCEL, p0, p1);
                }
                if (this.touch()) ev.stopPropagation();
            }
            ev.tch = MotionEvent.CANCEL; // propagate as cancel
        };
        let touchcancel = ev => {
            if (this.event.isDown() || this.eventsWhenUp) {
                //if ( captureWhenDown && elem.releasePointerCapture ) {
                //    elem.releasePointerCapture( ev.pointerId );
                //}
                this.updateEvent(ev.timeStamp, MotionEvent.CANCEL);
                if (this.touch()) ev.stopPropagation();
            }
        };

        // Detect touch based on what is supported in platform
        if (window.PointerEvent) {
            // Pointer events is the preferred new standard (but not available on iOS etc)
            // NOTE: touches identified by id for each event separately so we manage here to list first-touch-first
            // NOTE: pointerId will be zero when desktop+mouse so using constant instead
            let touches = [];
            let index = id => touches.findIndex(e => e && e.id === id);
            elem.onpointerdown = ev => {
                let id = ev.pointerId ? ev.pointerId : 1;
                touches.push({ id: id, x: ev.pageX, y: ev.pageY });
                touchdown(
                    ev,
                    touches[0],
                    touches.length > 1 ? touches[1] : null
                );
            };
            elem.onpointermove = ev => {
                let id = ev.pointerId ? ev.pointerId : 1;
                let i = index(id);
                if (i >= 0) {
                    touches[i].x = ev.pageX;
                    touches[i].y = ev.pageY;
                    touchmove(
                        ev,
                        touches[0],
                        touches.length > 1 ? touches[1] : null
                    );
                } else if (this.eventsWhenUp) {
                    touchmove(ev, { id: id, x: ev.pageX, y: ev.pageY });
                }
            };
            elem.onpointerup = ev => {
                let id = ev.pointerId ? ev.pointerId : 1;
                let i = index(id);
                if (i >= 0) {
                    touches[i] = null;
                    if (touches.every(e => e === null)) {
                        touches.length = 0;
                    }
                }
                touchup(
                    ev,
                    touches.length > 0 ? touches[0] : null,
                    touches.length > 1 ? touches[1] : null
                );
            };
            elem.onpointerleave = ev => {
                touches.length = 0;
                touchcancel(ev);
            };
        } else if ("ontouchstart" in window) {
            // Touch events
            // NOTE: gives touches as list first-touch-first
            let p = tch =>
                tch ? { id: tch.identifier, x: tch.pageX, y: tch.pageY } : null;
            elem.ontouchstart = ev => {
                touchdown(
                    ev,
                    p(ev.touches[0]),
                    ev.touches.length > 1 ? p(ev.touches[1]) : null
                );
            };
            elem.ontouchmove = ev => {
                touchmove(
                    ev,
                    p(ev.touches[0]),
                    ev.touches.length > 1 ? p(ev.touches[1]) : null
                );
            };
            elem.ontouchend = ev => {
                touchup(
                    ev,
                    p(ev.touches[0]),
                    ev.touches.length > 1 ? p(ev.touches[1]) : null
                );
            };
            elem.ontouchcancel = ev => {
                touchcancel(ev);
            };
        } else {
            // Mouse events
            // NOTE: single touch only
            elem.onmousedown = ev => {
                if (ev.button === 0) {
                    touchdown(ev, { id: 1, x: ev.pageX, y: ev.pageY });
                }
            };
            elem.onmousemove = ev => {
                if (ev.button === 0) {
                    touchmove(ev, { id: 1, x: ev.pageX, y: ev.pageY });
                }
            };
            elem.onmouseup = ev => {
                if (ev.button === 0) {
                    touchup(ev);
                }
            };
            elem.onmouseleave = ev => {
                if (ev.button === 0) {
                    touchcancel(ev);
                }
            };
        }

        if (gestureDetector) {
            this.onTouch = event => {
                gestureDetector.onTouch(event);
            };
        }
    }

    /** Updates our MotionEvent based on received touch events.
     *  NOTE: expects that p0 is first-touch and p1 is second-touch always
     *  NOTE: parameters are objects { id, x, y }
     */
    updateEvent(time, action, p0, p1) {
        //dlog("action: " + action);
        //dlog("p0: " + JSON.stringify(p0));
        //dlog("p1: " + JSON.stringify(p1));

        // If down and already any touch down => other down
        if (action === MotionEvent.DOWN && this.event.isDown()) {
            action = MotionEvent.OTHER_DOWN;
        }

        // Relative to element instead of page
        let xx, yy;
        if (p0 || p1) {
            let r = this.elem.getBoundingClientRect();
            xx = r.left + window.pageXOffset;
            yy = r.top + window.pageYOffset;
        }

        // First touch
        if (p0) {
            if (
                action === MotionEvent.DOWN ||
                action === MotionEvent.OTHER_DOWN
            ) {
                this.event.id = p0.id;
            }
            this.event.x = p0.x - xx;
            this.event.y = p0.y - yy;
        } else {
            this.event.id = 0;
        }

        // Second touch
        if (p1) {
            this.event.id1 = p1.id;
            this.event.x1 = p1.x - xx;
            this.event.y1 = p1.y - yy;
        } else {
            this.event.id1 = 0;
        }

        // Action
        if (action) {
            this.event.action = action;
        }

        // If was up but still any touch down => other up
        if (this.event.action === MotionEvent.UP && this.event.isDown()) {
            this.event.action = MotionEvent.OTHER_UP;
        }

        // Event time
        this.event.time = this.timeOffset + parseInt(time);
        if (this.event.time < 0) {
            // Happens on iPhone sometimes and seems completely random like iPhone miscalculates this
            // => hack to always positive because causes huge problems in upper level code if time is negative
            let n = parseInt(time);
            this.timeOffset = Math.abs(n) + 1;
            this.event.time = this.timeOffset + n;
        }

        // Log
        if (window.app.debugTouchEvents) {
            let s = this.event.time + " touch ";
            if (this.event.action === MotionEvent.DOWN) s += "down";
            else if (this.event.action === MotionEvent.UP) s += "up";
            else if (this.event.action === MotionEvent.MOVE) s += "move";
            else if (this.event.action === MotionEvent.CANCEL) s += "cancel";
            else if (this.event.action === MotionEvent.OTHER_DOWN)
                s += "other down";
            else if (this.event.action === MotionEvent.OTHER_UP)
                s += "other up";
            if (this.event.id) {
                s +=
                    " (" +
                    parseInt(this.event.x) +
                    "," +
                    parseInt(this.event.y) +
                    ")";
            }
            if (this.event.id1) {
                s +=
                    " (" +
                    parseInt(this.event.x1) +
                    "," +
                    parseInt(this.event.y1) +
                    ")";
            }
            dlog(s);
        }
    }

    touch() {
        return this.onTouch(this.event);
    }

    /** Override this to handle touch events. Return true if consumed event (stops further handling and propagation). */
    onTouch(/*MotionEvent*/ event) {
        return false;
    }
}

/** Multi-purpose gesture detector */
export class GestureDetector {
    features = {
        tap: false,
        longPress: false,
        scroll: false,
        scale: false,
        changeCursor: false
    };

    elem = null;
    state = 0;
    downEvent = null;
    lastEvent = null;
    longPressTimer = 0;
    changedCursor = false;

    /** constructor( features )
        constructor( elem, features )
    */
    constructor(a, b) {
        let features = null;
        if (a instanceof HTMLElement) {
            this.elem = a;
            features = b;
        } else {
            features = a;
        }
        if (this.elem) {
            new TouchHandler(this.elem, this);
        }
        if (features) {
            this.features.tap = Boolean(features.tap);
            this.features.longPress = Boolean(features.longPress);
            this.features.scroll = Boolean(features.scroll);
            this.features.scale = Boolean(features.scale);
            this.features.changeCursor = Boolean(features.changeCursor);

            // Scale can also be done with wheel
            if (this.features.scale && this.elem) {
                this.elem.addEventListener("wheel", ev => {
                    const scale = 1.1;
                    let x = this.lastEvent ? this.lastEvent.x : 0;
                    let y = this.lastEvent ? this.lastEvent.y : 0;
                    this.onScale(ev.deltaY < 0 ? scale : 1 / scale, x, y);
                });
            }
        }
    }

    /** Call this by passing touch events from TouchHandler */
    onTouch(event) {
        if (event.action === MotionEvent.DOWN) {
            // First touch down
            this.state = 1;
            this.downEvent = event.copy();

            // Long press
            if (this.features.longPress) {
                this.longPressTimer = setTimeout(() => {
                    let did = false;
                    if (this.downEvent) {
                        if (window.app.debugTouchEvents) {
                            dlog("long press");
                        }
                        did = this.onLongPress(this.downEvent);
                    }
                    if (did) {
                        this.cancel();
                    }
                }, 500);
            }
        } else if (event.action === MotionEvent.OTHER_DOWN) {
            if (event.id1) {
                // Second touch down
                if (this.downEvent) {
                    this.downEvent.id1 = event.id1;
                    this.downEvent.x1 = event.x1;
                    this.downEvent.y1 = event.y1;
                }

                // Cancel long press because second touch
                if (this.longPressTimer) {
                    clearTimeout(this.longPressTimer);
                    this.longPressTimer = 0;
                }
            }
        } else if (event.action === MotionEvent.OTHER_UP) {
            if (!event.id1) {
                // Second touch up
                if (this.downEvent) {
                    this.downEvent.id1 = 0;
                    this.downEvent.x1 = 0;
                    this.downEvent.y1 = 0;
                }

                if (this.state === 3) {
                    // Scaling => stop scaling
                    this.cancel();
                }
            }
        } else if (event.action === MotionEvent.MOVE) {
            if (this.state === 1) {
                // Detecting

                let scale = 0;
                if (this.features.scale && event.id1 && this.downEvent.id1) {
                    scale = this.getScaleBetweenEvents(this.downEvent, event);
                }
                if (
                    this.features.scale &&
                    scale &&
                    (scale <= 0.9 || scale >= 1.1)
                ) {
                    // Detected scaling
                    this.state = 3;
                } else if (this.features.scroll) {
                    let d0 = this.getDistanceBetween(
                        event.x,
                        event.y,
                        this.downEvent.x,
                        this.downEvent.y
                    );
                    if (d0 >= 20) {
                        // Detected scrolling
                        this.state = 2;
                    }
                }
            }

            if (this.state === 2) {
                // Scrolling
                if (this.downEvent) {
                    let e1 = this.downEvent;
                    let e2 = event;
                    let dx = event.x - this.lastEvent.x;
                    let dy = event.y - this.lastEvent.y;
                    this.onScroll(e1, e2, -dx, -dy);
                    if (this.features.changeCursor) {
                        this.elem.style.cursor = "grabbing";
                        this.changedCursor = true;
                    }
                }
            } else if (this.state === 3) {
                // Scaling

                let scale = 0;
                if (event.id1 && this.lastEvent.id1) {
                    scale = this.getScaleBetweenEvents(this.lastEvent, event);
                    //dlog(scale);
                }

                let x = (this.downEvent.x + this.downEvent.x1) / 2;
                let y = (this.downEvent.y + this.downEvent.y1) / 2;

                if (scale) {
                    this.onScale(scale, x, y);
                }
            }

            // Cancel long press if moved long enough OR detected something
            if (
                this.longPressTimer &&
                (this.state !== 1 ||
                    this.getDistanceBetween(
                        event.x,
                        event.y,
                        this.downEvent.x,
                        this.downEvent.y
                    ) > 5)
            ) {
                clearTimeout(this.longPressTimer);
                this.longPressTimer = 0;
            }
        } else if (event.action === MotionEvent.UP) {
            // First touch up
            if (this.state === 1) {
                // Still detecting
                if (
                    this.features.tap &&
                    this.getDistanceBetween(
                        event.x,
                        event.y,
                        this.downEvent.x,
                        this.downEvent.y
                    ) <= 5
                ) {
                    // User did tap
                    this.onTap(event);
                }
            }
            this.cancel();
        } else if (event.action === MotionEvent.CANCEL) {
            // Touch cancel
            this.cancel();
        }
        this.lastEvent = event.copy();
    }

    getDistanceBetween(x1, y1, x2, y2) {
        let dx = x2 - x1;
        let dy = y2 - y1;
        return Math.sqrt(dx * dx + dy * dy);
    }

    getScaleBetweenEvents(event1, event2) {
        let d1 = this.getDistanceBetween(
            event1.x1,
            event1.y1,
            event1.x,
            event1.y
        );
        let d2 = this.getDistanceBetween(
            event2.x1,
            event2.y1,
            event2.x,
            event2.y
        );
        return d2 / d1;
    }

    /** Cancels and clears current state */
    cancel() {
        if (this.state !== 0) {
            this.state = 0;
            this.downEvent = null;
            if (this.longPressTimer) {
                clearTimeout(this.longPressTimer);
                this.longPressTimer = 0;
            }
            if (this.changedCursor) {
                this.elem.style.cursor = "";
                this.changedCursor = false;
            }
        }
    }

    /** Single tap (click) */
    onTap(e) {}

    /** Long press. Return true if actually did something for long press (otherwise allow other gestures). */
    onLongPress(e) {
        return false;
    }

    /** Scroll
        e1: event when scrolling started (down)
        e2: current event
        dx,dy: from last onScroll()
    */
    onScroll(e1, e2, dx, dy) {}

    /** Scale */
    onScaleBegin() {}

    onScale(scale, x, y) {}

    onScaleEnd() {}
}

/** Helper to handle simple click */
export class ClickHandler {
    constructor(elem, func) {
        new (class extends GestureDetector {
            onTap() {
                func();
            }
        })(elem, { tap: true });
    }
}

export class HoverDetector {
    hover = false;

    constructor(elem) {
        new TouchHandler(elem, this, true);
        elem.style.touchAction = "";
    }

    onTouch(event) {
        let hover = event.action !== MotionEvent.CANCEL;
        if (this.hover !== hover) {
            this.hover = hover;
            this.onHover(hover);
        }
    }

    onHover(hover) {}
}

export class DragHandler {
    parent = null;
    children = [];
    onDrag = null;
    state = 0;
    dragging = null;
    offsetX = 0;
    left = 0;

    constructor(parent, children, onDrag) {
        this.parent = parent;
        this.children = children;
        this.onDrag = onDrag;

        // Prevent touch actions on children (like dragging)
        for (let child of children) {
            child.elem.addEventListener("mousedown", ev => {
                ev.preventDefault();
            });
        }

        new TouchHandler(parent, this, true, true);
    }

    getChildAt(pnt) {
        for (let child of this.children) {
            let r = getElementRect(child.elem);
            if (
                pnt.x >= r.left &&
                pnt.x < r.right &&
                pnt.y >= r.top &&
                pnt.y <= r.bottom
            ) {
                return child;
            }
        }
        return null;
    }

    onTouch(event) {
        //dlog(event);

        if (this.state === 0) {
            // Detecting
            if (event.action === MotionEvent.DOWN) {
                let child = this.getChildAt(event);
                if (child) {
                    // => dragging
                    this.parent.style.cursor = "grabbing";
                    this.state = 1;
                    this.dragging = child;
                    this.left = getElementRect(child.elem).left;
                    this.offsetX = event.x - this.left;
                }
            }
        } else if (this.state === 1) {
            // Dragging
            if (event.action === MotionEvent.MOVE) {
                this.left = event.x - this.offsetX;
                let min =
                    typeof this.dragging.min === "function"
                        ? this.dragging.min()
                        : this.dragging.min;
                let max =
                    typeof this.dragging.max === "function"
                        ? this.dragging.max()
                        : this.dragging.max;
                this.left = Math.max(this.left, min);
                this.left = Math.min(this.left, max);
                this.dragging.elem.style.left = this.left + "px";
                if (this.onDrag) {
                    this.onDrag(this.dragging.elem, this.left);
                }
            } else if (
                event.action === MotionEvent.UP ||
                event.action === MotionEvent.CANCEL
            ) {
                // => detecting
                this.parent.style.cursor = "";
                this.state = 0;
                this.dragging = null;
            }
        }
    }
}
