import React from "react";
import ReactDOM from "react-dom";

export default class Modal extends React.Component {
    render() {
        return ReactDOM.createPortal(
            <div
                onClick={this.props.onDismiss}
                className="ui dimmer modals visible active"
            >
                <div
                    style={{ position: "relative", width: "50%" }}
                    className="ui standard modal visible active"
                    onClick={e => e.stopPropagation()}
                >
                    <div className="header">{this.props.title}</div>
                    <div className="content">
                        {typeof this.props.content === "function"
                            ? this.props.content()
                            : this.props.content}
                    </div>
                    <div className="actions">{this.props.actions}</div>
                </div>
            </div>,
            document.querySelector("#modal")
        );
    }
}
