import React from "react";
import { Position, Site } from "./AppEngine";
import { TouchHandler, GestureDetector, MotionEvent } from "./Touch";
import { getElementRect, parseValidNumber, ReactHolder } from "./cupla";
import LockerView from "./LockerView";
import StorageView from "./StorageView";

export default class FieldMapPage extends React.Component {
    elem = null;
    inner = null;
    gestureDetector = null;
    dragObject = null;
    dragPosition = null;
    interval = 0;

    state = {
        edit: false,
        site: null,
        scrollX: 0,
        scrollY: 0,
        scale: 0.4,
        objects: []
    };

    constructor(props) {
        super(props);
        this.state.edit = Boolean(this.props.edit);
        window.map = this; //!
    }

    render() {
        return (
            <div
                ref={e => (this.elem = e)}
                className="NoSelect"
                style={{
                    flex: "1 1 auto",
                    overflow: "hidden"
                }}
            >
                <div
                    ref={e => (this.inner = e)}
                    style={{
                        position: "absolute",
                        transformOrigin: "0 0",
                        transform:
                            "translate(" +
                            this.state.scrollX +
                            "px," +
                            this.state.scrollY +
                            "px) scale(" +
                            this.state.scale +
                            ")" //, border: "1px solid black"
                    }}
                >
                    <img
                        src={
                            this.state.site
                                ? this.state.site.getMapImage()
                                : null
                        }
                        style={{
                            pointerEvents: "none"
                        }}
                        alt=""
                    />
                    {this.state.objects.map(holder => holder.render())}
                </div>
                {this.state.site ? (
                    <a
                        href={
                            "/sites/" +
                            this.state.site.id +
                            "/map" +
                            (this.state.edit ? "" : "/edit")
                        }
                        style={{
                            position: "absolute",
                            top: "6px",
                            left: "6px",
                            background: "#ccc",
                            padding: "4px 10px",
                            color: "black"
                        }}
                    >
                        {this.state.edit ? "edit" : "view"}
                    </a>
                ) : null}
            </div>
        );
    }

    async componentDidMount() {
        let that = this;
        this.gestureDetector = new (class extends GestureDetector {
            onTap(event) {
                that.onTap(event);
            }
            onLongPress(event) {
                return that.onLongPress(event);
            }
            onScroll(e1, e2, dx, dy) {
                that.onScroll(dx, dy);
            }
            onScale(scale, x, y) {
                that.onScale(scale, x, y);
            }
        })(this.elem, {
            tap: true,
            longPress: true,
            scroll: true,
            scale: true,
            changeCursor: true
        });
        new (class extends TouchHandler {
            onTouch(event) {
                if (that.onTouch(event)) {
                    that.gestureDetector.cancel();
                } else {
                    that.gestureDetector.onTouch(event);
                }
                return false;
            }
        })(this.elem, null, true);

        const siteID = parseValidNumber(this.props.match.params.id);
        const site = new Site(siteID);
        await site.pull();
        this.setState({ site });
        console.log(site);

        await site.pullStatus();

        let objects = [];
        objects.push(
            ...site.lockers.map(
                locker =>
                    new ReactHolder(
                        (
                            <MapLockerView
                                key={"L" + locker.id}
                                locker={locker}
                                map={this}
                            />
                        )
                    )
            ),
            ...site.storages.map(
                storage =>
                    new ReactHolder(
                        (
                            <MapStorageView
                                key={"S" + storage.id}
                                storage={storage}
                                map={this}
                            />
                        )
                    )
            )
        );
        this.setState({ objects });

        this.interval = setInterval(() => this.updateSiteStatus(), 10000);
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = 0;
        }
    }

    async updateSiteStatus() {
        if (this.state.site == null) {
            return;
        }

        await this.state.site.pullStatus();

        this.state.objects.forEach(obj => {
            if (obj.instance) {
                obj.instance.forceUpdate();
            }
        });
    }

    onTouch(event) {
        if (this.dragObject) {
            if (event.action === MotionEvent.MOVE) {
                let pnt = this.toPosition(event);
                let dx = pnt.x - this.dragPosition.x;
                let dy = pnt.y - this.dragPosition.y;
                this.dragObject.position.x += dx;
                this.dragObject.position.y += dy;
                this.dragPosition.set(pnt);
                this.dragObject.forceUpdate();
            } else if (event.action === MotionEvent.UP) {
                this.endDragObject();
            }
        }
    }

    onScroll(dx, dy) {
        if (!this.dragObject) {
            this.setState({
                scrollX: this.state.scrollX - dx,
                scrollY: this.state.scrollY - dy
            });
        }
    }

    onScale(scale, x, y) {
        // Adjust scale
        let newScale = this.state.scale * scale;

        // Adjust scroll to keep current zoom center
        let newScrollX = this.state.scrollX * scale;
        let newScrollY = this.state.scrollY * scale;

        // Adjust scroll towards new scale-point
        if (!isNaN(x) && !isNaN(y)) {
            newScrollX -= x * (scale - 1);
            newScrollY -= y * (scale - 1);
        }

        this.setState({
            scale: newScale,
            scrollX: newScrollX,
            scrollY: newScrollY
        });
    }

    onTap(event) {
        console.log(this.toPosition(event));
    }

    onLongPress(event) {
        if (this.state.edit) {
            let pnt = this.toPosition(event);
            for (let obj of this.state.objects) {
                if (obj.instance && obj.instance.elem) {
                    let r = this.getElemPositionRect(obj.instance.elem);
                    if (
                        pnt.x >= r.left &&
                        pnt.x < r.right &&
                        pnt.y >= r.top &&
                        pnt.y < r.bottom
                    ) {
                        this.beginDragObject(obj.instance, pnt);
                        return;
                    }
                }
            }
        }
    }

    onAppKeyUp(key) {
        console.log("key " + key);
        if (key === 187) {
            this.onScale(1.1);
        } else if (key === 189) {
            this.onScale(1 / 1.1);
        }
    }

    beginDragObject(obj, pos) {
        this.dragObject = obj;
        this.dragPosition = pos;
        this.updateObjects();
        //this.elem.style.cursor = "grabbing";
    }

    endDragObject() {
        if (this.dragObject) {
            this.dragObject.onEndDrag();
        }
        this.dragObject = null;
        this.dragPosition = null;
        //this.elem.style.cursor = "";
        this.gestureDetector.cancel();
        this.updateObjects();
    }

    updateObjects() {
        for (let obj of this.state.objects) {
            if (obj.instance) {
                obj.instance.update();
            }
        }
    }

    toPosition(pnt) {
        let r = getElementRect(this.elem);
        r.left += this.state.scrollX;
        r.top += this.state.scrollY;
        return new Position(
            (pnt.x - r.left) / this.state.scale,
            (pnt.y - r.top) / this.state.scale
        );
    }

    toPositionRect(rect) {
        let tl = this.toPosition({ x: rect.left, y: rect.top });
        let br = this.toPosition({ x: rect.right, y: rect.bottom });
        return { left: tl.x, top: tl.y, right: br.x, bottom: br.y };
    }

    getElemPositionRect(elem) {
        let rect = getElementRect(elem);
        let r = getElementRect(this.elem);
        return {
            left: (rect.left - r.left) / this.state.scale,
            top: (rect.top - r.top) / this.state.scale,
            right: (rect.right - r.left) / this.state.scale,
            bottom: (rect.bottom - r.top) / this.state.scale
        };
    }
}

class MapObject extends React.Component {
    position = new Position(0, 0);

    getStyle() {
        return {
            position: "absolute",
            left: this.position.x,
            top: this.position.y
        };
    }

    onEndDrag() {}

    update() {}
}

class MapLockerView extends MapObject {
    elem = null;
    locker = null;

    constructor(props) {
        super(props);
        this.locker = props.locker;
        this.position = new Position(this.locker.position);
        this.update();
    }

    render() {
        return this.props.map.state.edit
            ? this.renderEdit()
            : this.renderView();
    }

    renderEdit() {
        return (
            <div
                ref={e => (this.elem = e)}
                className="LockerView FlexVertical"
                style={{
                    width: "100px",
                    height: "100px",
                    background: "rgb(224, 225, 226)",
                    transform: "scale(0.25)",
                    justifyContent: "center",
                    ...this.getStyle()
                }}
            >
                <div
                    style={{
                        fontSize: "40px",
                        fontWeight: "bold"
                    }}
                >
                    L{this.locker.number}
                </div>
            </div>
        );
    }

    renderView() {
        return (
            <LockerView
                key={this.locker.number}
                locker={this.locker}
                lockerStatus={this.locker.status}
                site={this.locker.site}
                storageStatuses={null}
                vehicle={null}
                updateSiteStatus={null}
                style={{
                    width: "50px",
                    height: "50px",
                    maxWidth: "unset",
                    transform: "scale(0.5) translate(50px,50px)",
                    lineHeight: "15px",
                    ...this.getStyle()
                }}
            />
        );
    }

    update() {
        this.position.set(this.locker.position);
        if (this.elem) {
            if (this.props.map.dragObject === this) {
                this.elem.style.opacity = 1;
                this.elem.style.border = "2px solid black";
            } else if (
                this.props.map.dragObject &&
                this.props.map.dragObject !== this
            ) {
                this.elem.style.opacity = 0.3;
                this.elem.style.border = "";
            } else {
                this.elem.style.opacity = "";
                this.elem.style.border = "";
            }
        }
    }

    onEndDrag() {
        this.locker.position.x = Math.round(this.position.x);
        this.locker.position.y = Math.round(this.position.y);
        this.locker.pushPosition();
    }
}

class MapStorageView extends MapObject {
    elem = null;
    storage = null;

    constructor(props) {
        super(props);
        this.storage = props.storage;
        this.position = new Position(this.storage.position);
        this.update();
    }

    render() {
        return this.props.map.state.edit
            ? this.renderEdit()
            : this.renderView();
    }

    renderEdit() {
        return (
            <div
                ref={e => (this.elem = e)}
                className="LockerView FlexVertical"
                style={{
                    width: "100px",
                    height: "100px",
                    background: "rgb(224, 225, 226)",
                    transform: "scale(0.25)",
                    justifyContent: "center",
                    ...this.getStyle()
                }}
            >
                <div
                    style={{
                        fontSize: "40px",
                        fontWeight: "bold"
                    }}
                >
                    V{this.storage.number}
                </div>
            </div>
        );
    }

    renderView() {
        return (
            <StorageView
                key={this.storage.id}
                storage={this.storage}
                storageStatus={this.storage.status}
                style={{
                    width: "50px",
                    height: "50px",
                    maxWidth: "unset",
                    transform: "scale(0.5) translate(50px,50px)",
                    lineHeight: "15px",
                    padding: "2px",
                    ...this.getStyle()
                }}
            />
        );
    }

    update() {
        this.position.set(this.storage.position);
        if (this.elem) {
            if (this.props.map.dragObject === this) {
                this.elem.style.opacity = 1;
                this.elem.style.border = "2px solid black";
            } else if (
                this.props.map.dragObject &&
                this.props.map.dragObject !== this
            ) {
                this.elem.style.opacity = 0.3;
                this.elem.style.border = "";
            } else {
                this.elem.style.opacity = "";
                this.elem.style.border = "";
            }
        }
    }

    onEndDrag() {
        this.storage.position.x = Math.round(this.position.x);
        this.storage.position.y = Math.round(this.position.y);
        this.storage.pushPosition();
    }
}
