import React from "react";
import Modal from "./Modal";
import { nbsp } from "./cupla";

export default class SawView extends React.Component {
    state = {
        popup: false
    };

    render() {
        return (
            <>
                {this.renderPopup()}
                <div
                    style={{
                        display: "inline-flex",
                        margin: "0 2px 0 0",
                        minHeight: "unset",
                        padding: "5px",
                        background: "#F9FAFB",
                        border: "1px solid rgba(34,36,38,.15)",
                        borderRadius: ".28571429rem"
                    }}
                >
                    {this.renderSaw()}
                    <div
                        className="FlexHorizontal"
                        style={{ marginLeft: "0px" }}
                    >
                        {this.renderBatch(
                            this.props.sawStatus.batches.length >= 1
                                ? this.props.sawStatus.batches[0]
                                : null,
                            "Nykyinen",
                            true
                        )}
                        {this.renderBatch(
                            this.props.sawStatus.batches.length >= 2
                                ? this.props.sawStatus.batches[1]
                                : null,
                            "Seuraava",
                            false
                        )}
                    </div>
                </div>
            </>
        );
    }

    renderSaw() {
        return (
            <div style={{ padding: "5px" }}>
                <h2 style={{ textAlign: "center" }}>
                    {this.props.sawStatus && this.props.sawStatus.saw
                        ? this.props.sawStatus.saw.name
                        : nbsp()}
                </h2>
                <div
                    onClick={() =>
                        this.props.sawStatus.batches
                            ? this.handleStorage()
                            : null
                    }
                    className={
                        "massive ui button " +
                        (this.canHandleStorage()
                            ? "RoolsGreenBG Clickable"
                            : "")
                    }
                >
                    Vein
                    <br />
                    Sahapöydälle
                </div>
            </div>
        );
    }

    renderBatch(batchStatus, title, border) {
        return batchStatus ? (
            <div
                style={{
                    margin: "0 5px",
                    padding: "5px 15px",
                    borderRadius: "4px",
                    border: border ? "1px solid #005030" : ""
                }}
            >
                <h2 style={{ textAlign: "center" }}>{title}</h2>
                <div
                    className="FlexHorizontal"
                    style={{ margin: "4px 0", alignItems: "center" }}
                >
                    <h5 style={{ flex: "1 1 auto", margin: "0 10px 0 0" }}>
                        TUOTE
                    </h5>
                    <div
                        className="ui button RoolsGray"
                        style={{ width: "100px", cursor: "unset" }}
                    >
                        {window.eng.getProductName(batchStatus.product)}
                    </div>
                </div>
                <div
                    className="FlexHorizontal"
                    style={{ margin: "4px 0", alignItems: "center" }}
                >
                    <h5 style={{ flex: "1 1 auto", margin: "0 10px 0 0" }}>
                        JÄLJELLÄ
                    </h5>
                    <div
                        className="ui button RoolsGray"
                        style={{ width: "100px", cursor: "unset" }}
                    >
                        {batchStatus.count >= 0 ? batchStatus.count : nbsp()}
                    </div>
                </div>
                <div
                    className="FlexHorizontal"
                    style={{ margin: "4px 0", alignItems: "center" }}
                >
                    <h5 style={{ flex: "1 1 auto", margin: "0 10px 0 0" }}>
                        VARASTO
                    </h5>
                    <div
                        className="ui button RoolsGray"
                        style={{ width: "100px", cursor: "unset" }}
                    >
                        {window.eng.getStorageName(batchStatus.storage)}
                    </div>
                </div>
            </div>
        ) : null;
    }

    canHandleStorage() {
        return (
            this.props.vehicle &&
            this.props.sawStatus.saw &&
            this.props.sawStatus.batches &&
            this.props.sawStatus.batches.length >= 1 &&
            this.props.sawStatus.batches[0].storage
        );
    }

    async handleStorage() {
        if (this.canHandleStorage()) {
            const ok = await window.eng.handleStorage(
                this.props.site.id,
                this.props.sawStatus.saw.id,
                this.props.vehicle ? this.props.vehicle.id : 0,
                this.props.sawStatus.batches[0].storage.id
            );
            if (ok) {
                this.setState({ popup: true });
            }
        }
    }

    renderContent() {
        return (
            <div style={{ marginLeft: "35%", marginRight: "35%" }}>
                <i className="massive green check icon" />
            </div>
        );
    }

    popUpDismiss() {
        this.setState({ popup: false });
    }

    renderPopup() {
        if (this.state.popup) {
            setTimeout(() => {
                this.setState({ popup: false });
            }, 2000);
            return (
                <Modal
                    title="Toiminto suoritettu"
                    content={() => this.renderContent()}
                    onDismiss={() => this.popUpDismiss()}
                />
            );
        }
    }
}
