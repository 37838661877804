import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Dropdown } from "semantic-ui-react";
import Modal from "./Modal";
import { parseValidNumber } from "./cupla";

export default class StorageModal extends Component {
    state = {
        busy: false,
        productOptions: [],
        size: 0,
        name: "",
        product: 0,
        count: 0,
        showConfirmation: false
    };

    async componentDidMount() {
        const storageStatus = this.props.storageStatus;

        this.setState({
            size: storageStatus.storage.size,
            name: storageStatus.storage.name,
            count: storageStatus.count
        });

        const products = await window.eng.getSiteProducts(this.props.site.id);
        this.setState({
            productOptions: [
                { key: "", text: "", value: 0 },
                ...products.map(product => ({
                    key: product.id,
                    text: window.eng.getProductName(product),
                    value: product.id
                }))
            ],
            product: storageStatus.product ? storageStatus.product.id : 0
        });

        document.addEventListener("keydown", this.escFunction, false);
    }

    escFunction = event => {
        if (event.keyCode === 27) this.props.onDismiss();
    };

    handleChange = (e, { value }) => {
        this.setState({ product: value });
    };

    renderContent() {
        return (
            <div className="FlexVertical">
                <div className="PropertyRow">
                    <div>Nimi:</div>
                    <input
                        className="form-control"
                        onChange={e => this.setState({ name: e.target.value })}
                        type="text"
                        value={this.state.name}
                    />
                </div>
                <div className="PropertyRow">
                    <div>Koko (m2):</div>
                    <input
                        className="form-control"
                        onChange={e => this.setState({ size: e.target.value })}
                        type="number"
                        value={this.state.size}
                    />
                </div>
                <div className="PropertyRow">
                    <div>Tuote:</div>
                    <Dropdown
                        placeholder="Valitse tuote"
                        selection
                        options={this.state.productOptions}
                        value={this.state.product}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="PropertyRow">
                    <div>Kpl:</div>
                    <input
                        className="form-control"
                        onChange={e => this.setState({ count: e.target.value })}
                        type="number"
                        value={this.state.count}
                    />
                </div>
            </div>
        );
    }

    onSaveClick = async e => {
        if (this.props.method === "SAVE") {
            this.setState({ busy: true });
            let changed = false;

            // Storage size changed?
            if (
                this.state.size !== this.props.storageStatus.storage.size ||
                this.state.name !== this.props.storageStatus.storage.name
            ) {
                // Set storage
                await window.eng.setStorage(
                    this.props.storageStatus.storage.id,
                    parseValidNumber(this.state.size),
                    this.state.name
                );
                changed = true;
            }

            // Storage status changed (product+count)?
            if (
                this.state.product !==
                    (this.props.storageStatus.product
                        ? this.props.storageStatus.product.id
                        : 0) ||
                this.state.count !== this.props.storageStatus.count
            ) {
                // Set storage status
                await window.eng.setStorageStatus(
                    this.props.site.id,
                    this.props.storageStatus.storage.id,
                    this.state.product,
                    parseValidNumber(this.state.count)
                );
                changed = true;
            }

            if (changed) {
                await this.props.dataUpdate();
            }

            this.setState({ busy: false });
            this.props.onDismiss();
        }
    };

    renderActions() {
        return (
            <>
                <button
                    onClick={this.props.onDismiss}
                    className="ui button"
                    disabled={this.state.busy ? true : false}
                >
                    PERUUTA
                </button>
                <button
                    onClick={this.onSaveClick}
                    className="ui button RoolsGreenBG"
                    disabled={this.state.busy ? true : false}
                >
                    TALLENNA
                </button>
            </>
        );
    }

    renderConfirmationModal = () => {
        if (this.state.showConfirmation) {
            return (
                <Modal
                    onDismiss={this.onDismiss}
                    title=""
                    content={this.confirmContent()}
                    actions={this.confirmActions()}
                />
            );
        }
    };

    render() {
        return ReactDOM.createPortal(
            <div
                onClick={this.props.onDismiss}
                className="ui dimmer modals visible active"
            >
                <div
                    style={{ position: "relative", width: "400px" }}
                    className="ui standard modal visible active"
                    onClick={e => e.stopPropagation()}
                >
                    {this.renderConfirmationModal()}
                    <div className="header">{this.props.title}</div>
                    <div className="content">{this.renderContent()}</div>
                    <div className="actions">{this.renderActions()}</div>
                </div>
            </div>,
            document.querySelector("#modal")
        );
    }
}
