import React from "react";
import { User } from "./AppEngine";
import RoolsLogoImg from "./img/rools-logo.png";

export default class LoginPage extends React.Component {
    state = {
        userName: "",
        password: "",
        error: "",
        message: ""
    };

    onUserNameChange = e => {
        this.setState({ userName: e.target.value });
    };
    onPasswordChange = e => {
        this.setState({ password: e.target.value });
    };
    onErrorDismiss = () => {
        this.setState({ error: "" });
    };
    onMessageDismiss = () => {
        this.setState({ message: "" });
    };

    onFormSubmit = async e => {
        e.preventDefault();
        try {
            const response = await window.eng.login(
                this.state.userName,
                this.state.password
            );
            console.log(response);

            if (response && response.response === "OK") {
                let user = new User();
                user.setJSON(response.user);
                window.eng.setCurrentUser(user);

                this.props.onFormSubmit(user);
            } else {
                // Wrong email or password. Give error and clean the password field.
                this.setState({ error: "Väärä sähköposti tai salasana" });
                this.setState({ password: "" });
            }
        } catch (error) {
            console.error(error);
            console.log(error);
            //this.setState({ error });
        }
    };

    render() {
        return (
            <div className="RoolsGreenBG" style={{ height: "100%" }}>
                <div className="ui LoginContainer">
                    <div
                        className="FlexHorizontal"
                        style={{
                            width: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            marginBottom: "40px"
                        }}
                    >
                        <img
                            src={RoolsLogoImg}
                            style={{ height: "50px", marginRight: "2px" }}
                            alt=""
                        />
                        <div
                            style={{
                                fontSize: "30px",
                                fontWeight: "bold"
                            }}
                        >
                            ROOLS
                        </div>
                    </div>

                    <div className="column">
                        <h2
                            className="ui image header"
                            style={{
                                width: "100%",
                                textAlign: "center",
                                color: "white"
                            }}
                        >
                            <div className="content">Kirjaudu sisään</div>
                        </h2>

                        <form
                            onSubmit={this.onFormSubmit}
                            className="ui large form"
                        >
                            <div className="ui segment">
                                <div className="field">
                                    <div className="ui left icon input">
                                        <i className="user icon" />
                                        <input
                                            onChange={this.onUserNameChange}
                                            type="text"
                                            value={this.state.userName}
                                            placeholder="Sähköposti"
                                            autoComplete="on"
                                            name="email"
                                        />
                                    </div>
                                </div>

                                <div className="field">
                                    <div className="ui left icon input">
                                        <i className="lock icon" />
                                        <input
                                            onChange={this.onPasswordChange}
                                            type="password"
                                            value={this.state.password}
                                            placeholder="Salasana"
                                            name="password"
                                        />
                                    </div>
                                </div>
                                <button className="ui fluid large inverted RoolsGreenBG submit button Clickable">
                                    Kirjaudu
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
