import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import { nbsp, sortFunction } from "./cupla";

export default class LockersTableView extends Component {
    state = {
        lockerStatuses: []
    };

    componentDidMount() {
        if (this.props.siteStatus) {
            this.updateLockers();
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (this.props.siteStatus !== previousProps.siteStatus) {
            this.updateLockers();
        }
    }

    updateLockers() {
        this.setState({
            lockerStatuses: this.props.siteStatus.lockers.slice()
        });
    }

    handleSort = clickedColumn => () => {
        const { column, lockerStatuses, direction } = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                lockerStatuses: lockerStatuses.sort(
                    sortFunction(clickedColumn)
                ),
                direction: "ascending"
            });

            return;
        }

        this.setState({
            lockerStatuses: lockerStatuses.reverse(),
            direction: direction === "ascending" ? "descending" : "ascending"
        });
    };

    renderLockers() {
        return this.state.lockerStatuses.map(lockerStatus => {
            return (
                <Table.Row key={lockerStatus.locker.id}>
                    <Table.Cell>L{lockerStatus.locker.number}</Table.Cell>
                    <Table.Cell>
                        {window.eng.getProductName(lockerStatus.product)}
                    </Table.Cell>
                    <Table.Cell>
                        {lockerStatus.product
                            ? lockerStatus.product.timberDiameter
                            : ""}
                    </Table.Cell>
                    <Table.Cell>
                        {lockerStatus.product
                            ? lockerStatus.product.timberClass
                            : ""}
                    </Table.Cell>
                    <Table.Cell>
                        {lockerStatus.count ? lockerStatus.count : nbsp()}
                    </Table.Cell>
                    <Table.Cell>
                        {lockerStatus.percentage
                            ? lockerStatus.percentage + "%"
                            : ""}
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    render() {
        const { column, direction } = this.state;
        if (!this.props.siteStatus || !this.state.lockerStatuses.length) {
            return <div className="ui active loader"></div>;
        } else {
            return (
                <div className="PagePadding">
                    <Table selectable sortable celled collapsing compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell
                                    style={{ fontSize: "1.2em" }}
                                    colSpan="6"
                                >
                                    Lokerot
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell
                                    sorted={
                                        column === "number" ? direction : null
                                    }
                                    onClick={this.handleSort("number")}
                                >
                                    Numero
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={
                                        column === "name" ? direction : null
                                    }
                                    onClick={this.handleSort("name")}
                                >
                                    Tuotteen nimi
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={
                                        column === "timberDiameter"
                                            ? direction
                                            : null
                                    }
                                    onClick={this.handleSort("timberDiameter")}
                                >
                                    Läpimittaluokka
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={
                                        column === "timberClass"
                                            ? direction
                                            : null
                                    }
                                    onClick={this.handleSort("timberClass")}
                                >
                                    Tukkilaatu
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    style={{ minWidth: "70px" }}
                                    sorted={
                                        column === "count" ? direction : null
                                    }
                                    onClick={this.handleSort("count")}
                                >
                                    Kpl
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    style={{ minWidth: "70px" }}
                                    sorted={
                                        column === "percentage"
                                            ? direction
                                            : null
                                    }
                                    onClick={this.handleSort("percentage")}
                                >
                                    Täyttöaste
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>{this.renderLockers()}</Table.Body>
                    </Table>
                </div>
            );
        }
    }
}
