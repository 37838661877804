import React from "react";
import { Icon, Table } from "semantic-ui-react";

export default class CheckinPage extends React.Component {
    // Stages: SITE => SHIFT => VEHICLE => CONFIRMATION => HOME
    state = {
        stage: "SITE",
        sites: [],
        vehicles: []
    };

    async componentDidMount() {
        const sites = await window.eng.getSites();
        this.setState({ sites });
    }

    setStage = async (stage, updateVehicles) => {
        if (updateVehicles) {
            const vehicles = await window.eng.getSiteVehicles(
                window.eng.userSelections.site
                    ? window.eng.userSelections.site.id
                    : 0
            );
            this.setState({ vehicles });
        }
        this.setState({ stage });
    };

    confirmLogin = () => {
        this.props.onCheckin(window.eng.userSelections.site);
    };

    onLoginSubmit = e => {
        // If user is registered in more than 1 site, go to site selection.
        if (this.state.sites.length > 1) {
            this.setStage("SITE");
        } else {
            window.eng.userSelections.site = this.state.sites[0];
            this.setStage("SHIFT", true);
        }
    };

    onLogOut = () => {
        this.setStage("LOGIN");
        this.props.onLogOut();
    };

    render() {
        switch (this.state.stage) {
            case "SITE":
                return (
                    <>
                        <Step stage="Toimipiste" currStage={this.state.stage} />
                        <SiteScreen
                            sites={this.state.sites}
                            setStage={this.setStage}
                            onLogOut={this.onLogOut}
                        />
                    </>
                );

            case "SHIFT":
                return (
                    <>
                        <Step stage="Työvuoro" currStage={this.state.stage} />
                        <ShiftScreen
                            setStage={this.setStage}
                            onLogOut={this.onLogOut}
                        />
                    </>
                );

            case "VEHICLE":
                return (
                    <>
                        <Step stage="Työkone" currStage={this.state.stage} />
                        <VehicleScreen
                            vehicles={this.state.vehicles}
                            setStage={this.setStage}
                        />
                    </>
                );

            case "CONFIRMATION":
                return (
                    <>
                        <Step stage="Varmistus" currStage={this.state.stage} />
                        <ConfirmationScreen
                            setStage={this.setStage}
                            confirmLogin={this.confirmLogin}
                        />
                    </>
                );

            default:
                return null;
        }
    }
}

class Step extends React.Component {
    state = { steps: [] };

    componentDidMount() {
        const steps = [];
        steps.push({
            name: "Toimipiste",
            result: window.eng.userSelections.site
                ? window.eng.userSelections.site.name
                : "",
            icon: "sitemap"
        });
        steps.push({
            name: "Työvuoro",
            result: window.eng.userSelections.shift
                ? window.eng.userSelections.shift.name
                : "",
            icon: "calendar"
        });
        steps.push({
            name: "Työkone",
            result: window.eng.userSelections.vehicle
                ? window.eng.userSelections.vehicle.name
                : "",
            icon: "truck"
        });
        steps.push({ name: "Varmistus", result: "", icon: "checkmark" });
        this.setState({ steps });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.currStage !== prevProps.currStage) {
            const steps = [];
            steps.push({
                name: "Toimipiste",
                result: window.eng.userSelections.site
                    ? window.eng.userSelections.site.name
                    : "",
                icon: "sitemap"
            });
            steps.push({
                name: "Työvuoro",
                result: window.eng.userSelections.shift
                    ? window.eng.userSelections.shift.name
                    : "",
                icon: "calendar"
            });
            steps.push({
                name: "Työkone",
                result: window.eng.userSelections.vehicle
                    ? window.eng.userSelections.vehicle.name
                    : "",
                icon: "truck"
            });
            steps.push({ name: "Varmistus", result: "", icon: "checkmark" });
            this.setState({ steps });
        }
    }

    renderSteps() {
        return this.state.steps.map(step => {
            const iconClass = step.icon + " icon";
            const stepClass =
                this.props.stage === step.name ? "active step" : "step";
            return (
                <div className={stepClass}>
                    <i className={iconClass}></i>
                    <div className="content">
                        <div className="title">{step.name}</div>
                        <div className="description">{step.result}</div>
                    </div>
                </div>
            );
        });
    }

    render() {
        return (
            <div style={{ width: "100%" }} className="ui steps">
                {this.renderSteps()}
            </div>
        );
    }
}

class SiteScreen extends React.Component {
    onSiteClick = site => {
        window.eng.userSelections.site = site;
        this.props.setStage("SHIFT", true);
    };

    renderSites() {
        return this.props.sites.map(site => {
            return (
                <Table.Row
                    onClick={e => this.onSiteClick(site, e)}
                    key={site.id}
                >
                    <Table.Cell style={{ fontSize: "30px" }}>
                        {site.name}
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    render() {
        return (
            <div
                className="Container"
                style={{
                    width: "25%",
                    paddingTop: "5%",
                    margin: "auto"
                }}
            >
                <div className="ui segment">
                    <div className="ui grid">
                        <div className="thirteen wide column header">
                            <h1 className="header">Toimipiste</h1>
                        </div>
                        <div className="one wide column">
                            <i
                                onClick={this.props.onLogOut}
                                className="ExitIcon RoolsGreen window close icon big"
                            />
                        </div>
                    </div>
                    <div>
                        <Table size="large" basic="very" celled selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>{this.renderSites()}</Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}

class ShiftScreen extends React.Component {
    state = {
        shifts: [
            { id: 1, name: "Aamu", icon: "sun" },
            { id: 2, name: "Päivä", icon: "sun" },
            { id: 3, name: "Ilta", icon: "cloud" },
            { id: 4, name: "Yö", icon: "moon" }
        ]
    };

    onShiftClick = shift => {
        window.eng.userSelections.shift = shift;
        this.props.setStage("VEHICLE");
    };

    onPrevious = () => {
        if (this.props.sites && this.props.sites.length > 1) {
            this.props.setStage("SITE");
        } else {
            this.props.onLogOut();
        }
    };

    renderShifts() {
        return this.state.shifts.map(shift => {
            return (
                <Table.Row
                    onClick={e => this.onShiftClick(shift, e)}
                    key={shift.name}
                >
                    <Table.Cell style={{ fontSize: "30px" }}>
                        <Icon style={{ fontSize: "30px" }} name={shift.icon} />{" "}
                        {shift.name}
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    render() {
        return (
            <div
                className="Container"
                style={{
                    width: "25%",
                    paddingTop: "5%",
                    margin: "auto"
                }}
            >
                <div className="ui segment">
                    <div className="ui grid">
                        <div className="thirteen wide column header">
                            <h1 className="header">Työvuoro</h1>
                        </div>
                        <div className="one wide column">
                            <i
                                onClick={this.onPrevious}
                                className="ExitIcon RoolsGreen window close icon big"
                            />
                        </div>
                    </div>
                    <div>
                        <Table size="large" basic="very" celled selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>{this.renderShifts()}</Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}

class VehicleScreen extends React.Component {
    onVehicleClick = vehicle => {
        window.eng.userSelections.vehicle = vehicle;
        this.props.setStage("CONFIRMATION");
    };

    renderData() {
        return this.props.vehicles.map(vehicle => {
            return (
                <Table.Row
                    onClick={e => this.onVehicleClick(vehicle, e)}
                    key={vehicle.id}
                >
                    <Table.Cell style={{ fontSize: "30px" }}>
                        {vehicle.name}
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    onClick = () => {
        this.props.setStage("SHIFT");
    };

    render() {
        return (
            <div
                className="Container"
                style={{
                    width: "25%",
                    paddingTop: "5%",
                    margin: "auto"
                }}
            >
                <div className="ui segment">
                    <div className="ui grid">
                        <div className="thirteen wide column header">
                            <h1 className="header">Työkone</h1>
                        </div>
                        <div className="one wide column">
                            <i
                                onClick={this.onClick}
                                className="ExitIcon RoolsGreen window close icon big"
                            />
                        </div>
                    </div>
                    <div>
                        <Table size="large" basic="very" celled selectable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>{this.renderData()}</Table.Body>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}

class ConfirmationScreen extends React.Component {
    onClick = () => {
        this.props.confirmLogin();
    };
    onPrevious = () => {
        this.props.setStage("VEHICLE");
    };

    render() {
        return (
            <div
                className="Container"
                style={{
                    width: "25%",
                    paddingTop: "5%",
                    margin: "auto"
                }}
            >
                <div className="ui segment">
                    <div className="ui grid">
                        <div className="thirteen wide column header">
                            <h1 className="header">Jatka?</h1>
                        </div>
                        <div className="one wide column">
                            <i
                                onClick={this.onPrevious}
                                className="ExitIcon RoolsGreen window close icon big"
                            />
                        </div>
                    </div>
                    <button
                        onClick={this.onClick}
                        style={{ marginTop: "50px" }}
                        className="ui RoolsGreenBG huge button"
                    >
                        OK
                    </button>
                </div>
            </div>
        );
    }
}
