import React from "react";
import LockerView from "./LockerView";

export default class LockersView extends React.Component {
    elem = null;

    state = {
        storageStatuses: [],
        topLockerStatuses: [],
        bottomLockerStatuses: [],
        visible: true
    };

    componentDidMount() {
        if (this.props.siteStatus) {
            this.updateLockers();
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (this.props.siteStatus !== previousProps.siteStatus) {
            this.updateLockers();
        }
    }

    updateLockers() {
        this.setState({ storageStatuses: this.props.siteStatus.storages });
        this.setState({ vehicle: this.props.vehicle });

        let site = this.props.site;

        const topLockersValue =
            site.orientation === 0 ||
            site.orientation === 1 ||
            site.orientation === 3
                ? 0
                : 1;
        const topLockerStatuses = [];
        const bottomLockerStatuses = [];
        for (let lockerStatus of this.props.siteStatus.lockers) {
            if (lockerStatus.locker.number % 2 === topLockersValue) {
                topLockerStatuses.push(lockerStatus);
            } else {
                bottomLockerStatuses.push(lockerStatus);
            }
        }
        if (
            site.orientation === 0 ||
            site.orientation === 1 ||
            site.orientation === 2
        ) {
            topLockerStatuses.reverse();
            bottomLockerStatuses.reverse();
        }

        this.setState({ topLockerStatuses, bottomLockerStatuses });
    }

    renderLockers(lockerStatuses, side) {
        return lockerStatuses.map(lockerStatus => (
            <LockerView
                key={lockerStatus.locker.number}
                locker={lockerStatus.locker}
                lockerStatus={lockerStatus}
                side={side}
                site={this.props.site}
                storageStatuses={this.state.storageStatuses}
                vehicle={this.props.vehicle}
                updateSiteStatus={this.props.updateSiteStatus}
            />
        ));
    }

    render() {
        if (
            !this.props.siteStatus ||
            !this.props.siteStatus.lockers ||
            this.props.siteStatus.lockers.length === 0
        ) {
            return (
                <div
                    ref={e => (this.elem = e)}
                    className="ui active loader"
                ></div>
            );
        } else {
            return (
                <div ref={e => (this.elem = e)}>
                    <div style={{ margin: "100px 2px 120px 2px" }}>
                        <div
                            className="ui centered grid"
                            style={{ marginLeft: 0, marginRight: 0 }}
                        >
                            <div className="row" style={{ flexWrap: "nowrap" }}>
                                {this.renderLockers(
                                    this.state.topLockerStatuses,
                                    "TOP"
                                )}
                            </div>
                            <div className="row" style={{ flexWrap: "nowrap" }}>
                                {this.renderLockers(
                                    this.state.bottomLockerStatuses,
                                    "BOTTOM"
                                )}
                            </div>
                        </div>

                        <div className="ui grid"></div>
                    </div>
                </div>
            );
        }
    }
}
