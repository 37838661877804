import React from "react";
import SawsView from "./SawsView";
import LockersView from "./LockersView";
import LockersTableView from "./LockersTableView";
import { Dropdown } from "semantic-ui-react";
import StoragesTableView from "./StoragesTableView";
import StoragesView from "./StoragesView";
import {
    getDurationStringMin,
    currentTime,
    getElementRect,
    padzero,
    parseValidNumber
} from "./cupla";
import OkImg from "./img/ok.png";
import WarningImg from "./img/warning.png";
import ErrorImg from "./img/error.png";

export default class FieldPage extends React.Component {
    interval = 0;
    contentView = null;
    lockersView = null;
    topStoragesView = null;
    bottomStoragesView = null;

    state = {
        site: null,
        siteStatus: null,
        column: null,
        direction: null,
        tableView: false,
        lockerView: true,
        vehicleOptions: [],
        currentVehicle: null,
        loading: false,
        warning: null,
        error: null,
        warningErrorTime: null,
        time: ""
    };

    async componentDidMount() {
        const siteId = parseValidNumber(this.props.match.params.id);
        const site = await window.eng.getSite(siteId);
        this.setState({ site });
        console.log(site);

        const vehicles = await window.eng.getSiteVehicles(siteId);
        let vehicleOptions = [];
        if (!window.eng.user.isDriver()) {
            vehicleOptions.push({
                key: "",
                text: "Valvoja",
                value: 0,
                vehicle: null
            });
        }
        for (let vehicle of vehicles) {
            vehicleOptions.push({
                key: vehicle.name,
                text: vehicle.name,
                value: vehicleOptions.length,
                vehicle: vehicle
            });
        }
        this.setState({ vehicleOptions });
        if (this.state.currentVehicle === null && vehicleOptions.length) {
            this.setState({ currentVehicle: vehicleOptions[0].vehicle });
        }

        await this.updateSiteStatus();
        if (this.lockersView) {
            this.interval = setInterval(() => this.updateSiteStatus(), 10000);
            this.onLockersViewClick(true);
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = 0;
        }
    }

    async updateSiteStatus() {
        if (this.state.site == null) {
            return;
        }
        //console.log("updateSiteStatus");

        // Time
        var now = new Date();
        var time =
            padzero(now.getHours(), 2) + ":" + padzero(now.getMinutes(), 2);
        this.setState({ time: time });

        // Site status
        this.setState({ loading: true });
        const siteStatus = await window.eng.getSiteStatus(
            this.state.site.id,
            this.state.currentVehicle ? this.state.currentVehicle.id : 0
        );
        console.log(siteStatus);
        this.setState({ loading: false });
        if (siteStatus) {
            this.setState({ siteStatus });
        }

        // Warning or error?
        let warning = null;
        let error = null;
        let warningErrorTime = null;
        if (siteStatus === null) {
            error = "ROOLS palvelinvirhe";
        } else if (siteStatus.integrationStatus) {
            // Error when integration totally fails
            let time = new Date(siteStatus.integrationStatus.time);
            if (time) {
                let dtime = currentTime() - time.getTime();
                //console.log(dtime);
                let mins = Math.floor(dtime / (60 * 1000));
                if (mins < 0) {
                    error = "Aikavirhe";
                } else if (mins > 1) {
                    warningErrorTime = getDurationStringMin(mins);
                    error =
                        "UPM rajapinta toiminut viimeksi " +
                        warningErrorTime +
                        " sitten";
                }
            } else {
                error = "Aikavirhe";
            }

            // Warning only when no error
            if (!error) {
                time = new Date(siteStatus.integrationStatus.yieldsTime);
                if (time) {
                    let dtime = currentTime() - time.getTime();
                    //console.log(dtime);
                    let mins = Math.floor(dtime / (60 * 1000));
                    if (mins < 0) {
                        warning = "Aikavirhe";
                    } else if (mins >= 5) {
                        warningErrorTime = getDurationStringMin(mins);
                        warning =
                            "UPM rajapinnasta saatu lajittelutietoja viimeksi " +
                            warningErrorTime +
                            " sitten";
                    }
                } else {
                    warning = "Aikavirhe";
                }
            }
        }
        this.setState({ warning, error, warningErrorTime });
    }

    onTableViewClick() {
        this.setState({ tableView: true });
    }

    onVisualViewClick() {
        this.setState({ tableView: false });
    }

    onLockersViewClick(jump) {
        this.setState({ lockerView: true });
        if (!this.state.tableView) {
            let rr = getElementRect(this.contentView);
            rr.top -= 190;
            let r = getElementRect(this.lockersView.elem, true);
            this.scrollTo(
                (r.top + r.bottom) / 2 - (rr.bottom - rr.top) / 2,
                jump ? false : true
            );
        }
    }

    onStoragesViewClick(number) {
        this.setState({ lockerView: false });
        if (!this.state.tableView) {
            if (number === 1) {
                this.scrollTo(0, true);
            } else if (number === 2) {
                this.scrollTo(
                    getElementRect(this.bottomStoragesView.elem, true).bottom,
                    true
                );
            }
        }
    }

    scrollTo(y, smooth) {
        this.contentView.scrollTo({
            top: y,
            behavior: smooth ? "smooth" : undefined
        });
    }

    renderViewButtons() {
        let selected = "ui button RoolsGreenBG";
        let unselected = "ui button";
        return (
            <div>
                <div className="ui buttons">
                    <button
                        onClick={() => this.onVisualViewClick()}
                        className={this.state.tableView ? unselected : selected}
                    >
                        Visuaalinen
                    </button>
                    <div className="or" data-text="" />
                    <button
                        onClick={() => this.onTableViewClick()}
                        className={this.state.tableView ? selected : unselected}
                    >
                        Taulukko
                    </button>
                </div>

                {this.state.tableView ? (
                    <div style={{ marginLeft: "10px" }} className="ui buttons">
                        <button
                            onClick={() => this.onLockersViewClick()}
                            className={
                                this.state.lockerView ? selected : unselected
                            }
                        >
                            Lokerot
                        </button>
                        <div className="or" data-text="" />
                        <button
                            onClick={() => this.onStoragesViewClick()}
                            className={
                                this.state.lockerView ? unselected : selected
                            }
                        >
                            Varastot
                        </button>
                    </div>
                ) : (
                    <div style={{ marginLeft: "10px" }} className="ui buttons">
                        <button
                            onClick={() => this.onLockersViewClick()}
                            className={unselected}
                        >
                            Lokerot
                        </button>
                        <div className="or" data-text="" />
                        <button
                            onClick={() => this.onStoragesViewClick(1)}
                            className={unselected}
                        >
                            Varastot 1
                        </button>
                        <div className="or" data-text="" />
                        <button
                            onClick={() => this.onStoragesViewClick(2)}
                            className={unselected}
                        >
                            Varastot 2
                        </button>
                    </div>
                )}
            </div>
        );
    }

    onChangeVehicle = async (e, { value }) => {
        await this.setState({
            currentVehicle: this.state.vehicleOptions[value].vehicle
        });

        // Clear vehicle dependant data until fetched from server
        if (this.state.siteStatus) {
            for (let o of this.state.siteStatus.lockers) {
                o.percentage = NaN;
            }
            for (let o of this.state.siteStatus.storages) {
                o.percentage = NaN;
            }
        }

        this.updateSiteStatus();
    };

    render() {
        return (
            <div className="FlexVertical" style={{ overflow: "hidden" }}>
                <div className="FlexHorizontal" style={{ padding: "20px" }}>
                    <div style={{ flex: "1 1 auto", marginRight: "10px" }}>
                        <h2>
                            {this.state.site
                                ? this.state.site.name.toUpperCase()
                                : ""}
                        </h2>
                    </div>
                    <div style={{ flex: "0 0 auto", marginRight: "10px" }}>
                        <Dropdown
                            selection
                            defaultValue={0}
                            options={this.state.vehicleOptions}
                            onChange={this.onChangeVehicle}
                        />
                    </div>

                    <div style={{ flex: "0 0 auto" }}>
                        {this.renderViewButtons()}
                    </div>

                    {this.renderLoader()}
                </div>
                {this.renderView()}
            </div>
        );
    }

    renderLoader() {
        return (
            <div
                style={{
                    flex: "0 0 auto",
                    alignSelf: "center",
                    width: "80px",
                    textAlign: "right"
                }}
            >
                {this.state.loading ? (
                    <i
                        style={{ marginRight: "10px" }}
                        className="spinner circle loading icon"
                    />
                ) : this.state.error || this.state.warning ? (
                    <div
                        className="FlexVertical"
                        style={{
                            position: "absolute",
                            left: "8px",
                            top: "-10px",
                            alignItems: "center"
                        }}
                    >
                        <img
                            style={{
                                height: "40px",
                                animation: "blink 1s infinite"
                            }}
                            src={this.state.error ? ErrorImg : WarningImg}
                            alt=""
                            title={this.state.error || this.state.warning}
                        />
                        <div
                            style={{
                                fontSize: "12px",
                                transform: "translateY(-8px)"
                            }}
                        >
                            {this.state.warningErrorTime}
                        </div>
                    </div>
                ) : (
                    <img
                        style={{
                            position: "absolute",
                            left: "9px",
                            top: "-8px",
                            height: "34px"
                        }}
                        src={OkImg}
                        alt=""
                    />
                )}
                {this.state.time}
            </div>
        );
    }

    renderView() {
        if (this.state.site === null) {
            return null;
        }
        if (!this.state.tableView) {
            return (
                <div className="FlexVertical" style={{ overflow: "hidden" }}>
                    <div
                        ref={e => (this.contentView = e)}
                        className="FlexVertical ScrollbarY"
                        style={{ paddingTop: "192px" }}
                    >
                        {this.state.site.orientation > 0 ? (
                            <StoragesView
                                ref={e => (this.topStoragesView = e)}
                                site={this.state.site}
                                siteStatus={this.state.siteStatus}
                                oddStorages={true}
                                vehicle={this.state.currentVehicle}
                                updateSiteStatus={() => this.updateSiteStatus()}
                                isTop={true}
                            />
                        ) : null}

                        <LockersView
                            ref={e => (this.lockersView = e)}
                            site={this.state.site}
                            siteStatus={this.state.siteStatus}
                            vehicle={this.state.currentVehicle}
                            updateSiteStatus={() => this.updateSiteStatus()}
                        />

                        {this.state.site.orientation > 0 ? (
                            <StoragesView
                                ref={e => (this.bottomStoragesView = e)}
                                site={this.state.site}
                                siteStatus={this.state.siteStatus}
                                oddStorages={true}
                                vehicle={this.state.currentVehicle}
                                updateSiteStatus={() => this.updateSiteStatus()}
                                isBottom={true}
                            />
                        ) : null}

                        {this.state.site.orientation === 0 ? (
                            <>
                                <StoragesView
                                    ref={e => (this.topStoragesView = e)}
                                    site={this.state.site}
                                    siteStatus={this.state.siteStatus}
                                    vehicle={this.state.currentVehicle}
                                    updateSiteStatus={() =>
                                        this.updateSiteStatus()
                                    }
                                    isTop={true}
                                />
                                <StoragesView
                                    ref={e => (this.bottomStoragesView = e)}
                                    site={this.state.site}
                                    siteStatus={this.state.siteStatus}
                                    vehicle={this.state.currentVehicle}
                                    updateSiteStatus={() =>
                                        this.updateSiteStatus()
                                    }
                                    isBottom={true}
                                />
                            </>
                        ) : null}
                    </div>
                    <div
                        style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            background: "white",
                            borderBottomRightRadius: "4px"
                        }}
                    >
                        <SawsView
                            site={this.state.site}
                            sawStatuses={
                                this.state.siteStatus
                                    ? this.state.siteStatus.saws
                                    : null
                            }
                            vehicle={this.state.currentVehicle}
                            style={{ margin: "0 10px 3px 3px" }}
                        />
                    </div>
                </div>
            );
        } else {
            if (this.state.lockerView) {
                return (
                    <LockersTableView
                        site={this.state.site}
                        siteStatus={this.state.siteStatus}
                        vehicle={this.state.currentVehicle}
                    />
                );
            } else {
                return (
                    <StoragesTableView
                        site={this.state.site}
                        siteStatus={this.state.siteStatus}
                        vehicle={this.state.currentVehicle}
                    />
                );
            }
        }
    }
}
