import axios from "axios";
import { getByID, nbsp, parseValidNumber } from "./cupla";
import assert from "assert";
import SeikkuMap from "./img/map_seikku.png";
import KuhmoMap from "./img/map_kuhmo.jpg";
import KaukasMap from "./img/map_kaukas.png";

export const api = axios.create({
    baseURL: "https://timber.rools.fi:5487"
});

/** The App Engine */
export class AppEngine {
    user = null;
    userSelections = {
        site: null,
        vehicle: null,
        shift: null
    };

    constructor() {
        this.restoreLogin();
    }

    getCurrentUser() {
        return this.user;
    }

    setCurrentUser(user) {
        this.user = user;
        //! replace with only key
        if (this.user) {
            localStorage.setItem("user", JSON.stringify(this.user.getJSON()));
        } else {
            localStorage.removeItem("user");
        }
        this.userSelections.site = null;
        this.userSelections.vehicle = null;
        this.userSelections.shift = null;
        //!window.app.update();
    }

    restoreLogin() {
        let json = localStorage.getItem("user");
        if (json) {
            json = JSON.parse(json);
            if (json && json.userkey) {
                this.user = new User();
                this.user.setJSON(json);
            }
        }
    }

    async login(email, password) {
        let [resp] = await this.request({
            method: "post",
            url: "users/login",
            data: {
                email: email,
                password: password
            }
        });
        return resp;
    }

    /** Logs out user */
    async logout() {
        await window.eng.driverEndWork();
        this.setCurrentUser(null);
    }

    async request(config) {
        try {
            config = Object.assign(
                {
                    headers: {
                        Authorization: window.eng.user
                            ? window.eng.user.userkey
                            : ""
                    },
                    responseType: "json"
                },
                config
            );
            let response = await api.request(config);
            if (response.status >= 200 && response.status <= 299) {
                //dlog(response.data);
                return [response.data, null];
            }
            return [null, response.status];
        } catch (error) {
            let msg = error.message;
            if (
                error.response &&
                error.response.data &&
                error.response.data.message
            ) {
                let s = error.response.data.message.trim();
                if (s) {
                    msg += ": " + s;
                }
            }
            console.log(msg);
            return [null, msg];
        }
    }

    async getCustomers() {
        const [resp] = await this.request({
            method: "get",
            url: "customers"
        });
        return resp ? resp : [];
    }

    async setCustomer(customer) {
        let [, err] = await this.request({
            method: "post",
            url: "customers/set",
            data: customer
        });
        return err == null;
    }

    async deleteCustomer(id) {
        let [, err] = await this.request({
            method: "delete",
            url: "customers/" + id
        });
        return err == null;
    }

    getProductName(product) {
        return product
            ? product.name
                ? product.name
                : "T" + product.code
            : nbsp();
    }

    async setProduct(product) {
        let [, err] = await this.request({
            method: "post",
            url: "products/set",
            data: product
        });
        return err == null;
    }

    async deleteProduct(id) {
        let [, err] = await this.request({
            method: "delete",
            url: "products/" + id
        });
        return err == null;
    }

    async setVehicle(vehicle) {
        let [, err] = await this.request({
            method: "post",
            url: "vehicles/set",
            data: vehicle
        });
        return err == null;
    }

    async deleteVehicle(id) {
        let [, err] = await this.request({
            method: "delete",
            url: "vehicles/" + id
        });
        return err == null;
    }

    async getUsers(siteID) {
        const [resp] = await this.request({
            method: "get",
            url: "users/site/" + siteID
        });
        return resp ? resp : [];
    }

    async setUser(user) {
        let [, err] = await this.request({
            method: "post",
            url: "users/set",
            data: user
        });
        return err == null;
    }

    async deleteUser(id) {
        let [, err] = await this.request({
            method: "delete",
            url: "users/" + id
        });
        return err == null;
    }

    async setSite(site) {
        let [, err] = await this.request({
            method: "post",
            url: "sites/set",
            data: site
        });
        return err == null;
    }

    async deleteSite(id) {
        let [, err] = await this.request({
            method: "delete",
            url: "sites/" + id
        });
        return err == null;
    }

    async getSites() {
        const [resp] = await this.request({
            method: "get",
            url: "sites"
        });
        return resp ? resp : [];
    }

    async getSite(id) {
        const [resp] = await this.request({
            method: "get",
            url: "sites/" + id
        });
        return resp;
    }

    async getSiteVehicles(siteID) {
        const [resp] = await this.request({
            method: "get",
            url: "sites/" + siteID + "/vehicles"
        });
        return resp ? resp : [];
    }

    async getSiteStatus(siteID, vehicleID) {
        const [resp] = await this.request({
            method: "get",
            url: "sites/" + siteID + "/status/" + vehicleID
        });
        //! this could be determined in server
        if (resp && resp.saws) {
            for (let saw of resp.saws) {
                if (saw.batches && saw.batches.length) {
                    let batch = saw.batches[0];
                    if (batch.storage && batch.storage.id) {
                        for (let storageStatus of resp.storages) {
                            if (
                                storageStatus.storage &&
                                storageStatus.storage.id === batch.storage.id
                            ) {
                                storageStatus.batchAtStorage = batch;
                            }
                        }
                    }
                }
            }
        }
        return resp;
    }

    async getSiteProducts(siteID) {
        if (siteID instanceof Site) {
            siteID = siteID.id; //!
        }
        const [resp] = await this.request({
            method: "get",
            url: "sites/" + siteID + "/products"
        });
        return resp ? resp : [];
    }

    async handleLocker(siteID, vehicleID, lockerID, storageID) {
        let [, err] = await this.request({
            method: "post",
            url: "handleLocker",
            data: {
                site: siteID,
                vehicle: vehicleID,
                locker: lockerID,
                storage: storageID
            }
        });
        return err == null;
    }

    async handleStorage(siteID, sawID, vehicleID, storageID) {
        let [, err] = await this.request({
            method: "post",
            url: "handleStorage",
            data: {
                site: siteID,
                saw: sawID,
                vehicle: vehicleID,
                storage: storageID
            }
        });
        return err == null;
    }

    async setStorage(id, size, name) {
        let [, err] = await this.request({
            method: "post",
            url: "setStorage",
            data: {
                id: id,
                size: size,
                name: name
            }
        });
        return err == null;
    }

    getStorageName(storage) {
        return storage
            ? storage.name
                ? storage.name
                : "V" + storage.number
            : nbsp();
    }

    async setStorageProduct(siteID, storageID, productID) {
        let [, err] = await this.request({
            method: "post",
            url: "setStorageProduct",
            data: {
                site: siteID,
                storage: storageID,
                product: productID
            }
        });
        return err == null;
    }

    async setLockerStatus(siteID, lockerID, productID, count) {
        let [, err] = await this.request({
            method: "post",
            url: "setLockerStatus",
            data: {
                site: siteID,
                locker: lockerID,
                product: productID,
                count: count
            }
        });
        return err == null;
    }

    async setStorageStatus(siteID, storageID, productID, count) {
        let [, err] = await this.request({
            method: "post",
            url: "setStorageStatus",
            data: {
                site: siteID,
                storage: storageID,
                product: productID,
                count: count
            }
        });
        return err == null;
    }

    async driverBegWork() {
        if (this.userSelections.site.id === 0) {
            return false;
        }
        let [, err] = await this.request({
            method: "post",
            url: "driverBegWork",
            data: {
                site: this.userSelections.site
                    ? this.userSelections.site.id
                    : 0,
                vehicle: this.userSelections.vehicle
                    ? this.userSelections.vehicle.id
                    : 0,
                shift: this.userSelections.shift
                    ? this.userSelections.shift.id
                    : 0
            }
        });
        return err == null;
    }

    async driverEndWork() {
        if (!this.userSelections.site || this.userSelections.site.id === 0) {
            return false;
        }
        let [, err] = await this.request({
            method: "post",
            url: "driverEndWork",
            data: {
                site: this.userSelections.site
                    ? this.userSelections.site.id
                    : 0,
                vehicle: this.userSelections.vehicle
                    ? this.userSelections.vehicle.id
                    : 0,
                shift: this.userSelections.shift
                    ? this.userSelections.shift.id
                    : 0
            }
        });
        return err == null;
    }
}

/** Entity base class */
class Entity {
    id = 0;
    state = 0;

    setJSON(json) {
        if (typeof json.id !== "undefined") {
            this.id = parseValidNumber(json.id);
        }
        if (typeof json.state !== "undefined") {
            this.state = json.state;
        }
    }

    getJSON() {
        return {
            id: this.id,
            state: this.state
        };
    }
}

export class Position {
    x = NaN;
    y = NaN;

    constructor(x, y) {
        this.set(x, y);
    }

    set(x, y) {
        if (x instanceof Position) {
            this.x = x.x;
            this.y = x.y;
        } else if (typeof x !== "undefined" && typeof y !== "undefined") {
            this.x = x;
            this.y = y;
        }
    }

    setJSON(json) {
        this.x = json.x;
        this.y = json.y;
    }

    getJSON() {
        return {
            x: this.x,
            y: this.y
        };
    }
}

/** User */
export class User extends Entity {
    userkey = "";
    email = "";
    firstName = "";
    lastName = "";
    features = "";

    setJSON(json) {
        super.setJSON(json);
        this.userkey = json.userkey;
        this.email = json.email;
        this.firstName = json.firstName;
        this.lastName = json.lastName;
        this.features = json.features;
    }

    getJSON() {
        let json = super.getJSON();
        json.userkey = this.userkey;
        json.email = this.email;
        json.firstName = this.firstName;
        json.lastName = this.lastName;
        json.features = this.features;
        return json;
    }

    isSuper() {
        return this.features.includes("S");
    }

    isAdmin() {
        return this.features.includes("A") || this.isSuper();
    }

    isManager() {
        return this.features.includes("M");
    }

    isDriver() {
        return this.features.includes("D");
    }
}

export class Site extends Entity {
    lockers = [];
    storages = [];
    saws = [];
    status = {
        time: null
    };
    colorLimit1 = 0;
    colorLimit2 = 0;

    constructor(id) {
        super();
        this.id = id;
    }

    async pull() {
        const [resp] = await window.eng.request({
            method: "get",
            url: "sites/" + this.id
        });
        this.setJSON(resp);
        return resp;
    }

    setJSON(json) {
        this.name = json.name;
        this.colorLimit1 = json.colorLimit1;
        this.colorLimit2 = json.colorLimit2;
    }

    /** Pulls site status */
    async pullStatus(vehicle) {
        const [resp] = await window.eng.request({
            method: "get",
            url: "sites/" + this.id + "/status/" + (vehicle ? vehicle.id : 0)
        });
        console.log(resp);
        if (resp) {
            //! this could be determined in server
            if (resp.saws) {
                for (let saw of resp.saws) {
                    if (saw.batches && saw.batches.length) {
                        let batch = saw.batches[0];
                        if (batch.storage && batch.storage.id) {
                            for (let storageStatus of resp.storages) {
                                if (
                                    storageStatus.storage &&
                                    storageStatus.storage.id ===
                                        batch.storage.id
                                ) {
                                    storageStatus.batchAtStorage = batch;
                                }
                            }
                        }
                    }
                }
            }

            this.status.time = resp.time;

            for (let js of resp.lockers) {
                this.getLocker(js.locker.id, true).setJSON(js.locker, js);
            }

            for (let js of resp.storages) {
                this.getStorage(js.storage.id, true).setJSON(js.storage, js);
            }
        }
    }

    getLocker(id, addIfNotFound) {
        let locker = getByID(this.lockers, id);
        if (locker === null && addIfNotFound) {
            locker = new Locker(this);
            locker.id = id;
            this.lockers.push(locker);
        }
        return locker;
    }

    getStorage(id, addIfNotFound) {
        let storage = getByID(this.storages, id);
        if (storage === null && addIfNotFound) {
            storage = new Storage(this);
            storage.id = id;
            this.storages.push(storage);
        }
        return storage;
    }

    getMapImage() {
        if (this.id === 13) {
            return KaukasMap;
        } else if (this.id === 14) {
            return SeikkuMap;
        } else if (this.id === 20) {
            return KuhmoMap;
        }
        return null;
    }
}

export class Locker extends Entity {
    site = null;
    number = 0;
    position = new Position();

    status = {
        count: NaN,
        percentage: NaN,
        product: null
    };

    constructor(site) {
        super();
        assert(site instanceof Site);
        this.site = site;
    }

    setJSON(json, jsonStatus) {
        this.number = json.number;
        this.position.setJSON(json);
        if (jsonStatus) {
            this.status.count = jsonStatus.count;
            this.status.percentage = jsonStatus.percentage;
            this.status.product = jsonStatus.product; //!
        }
    }

    async pushPosition() {
        let [, err] = await window.eng.request({
            method: "patch",
            url: "lockers/" + this.id + "/position",
            data: this.position.getJSON()
        });
        return err === null;
    }
}

export class Storage extends Entity {
    site = null;
    number = 0;
    position = new Position();

    status = {
        count: NaN,
        percentage: NaN,
        product: null
    };

    constructor(site) {
        super();
        assert(site instanceof Site);
        this.site = site;
    }

    setJSON(json, jsonStatus) {
        this.number = json.number;
        this.position.setJSON(json);
        if (jsonStatus) {
            this.status.count = jsonStatus.count;
            this.status.percentage = jsonStatus.percentage;
            this.status.product = jsonStatus.product; //!
        }
    }

    async pushPosition() {
        let [, err] = await window.eng.request({
            method: "patch",
            url: "storages/" + this.id + "/position",
            data: this.position.getJSON()
        });
        return err === null;
    }
}
