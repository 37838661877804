import React, { Component } from "react";
import ProductModal from "./ProductModal";
import { Table } from "semantic-ui-react";
import { sortFunction, parseValidNumber } from "./cupla";

export default class ProductsPage extends Component {
    state = {
        site: null,

        productData: [],

        column: null,
        direction: null,
        paths: [],

        selectedProduct: null,
        showEditModal: false,
        showAddModal: false
    };

    async componentDidMount() {
        const siteId = parseValidNumber(this.props.match.params.id);
        const site = await window.eng.getSite(siteId);
        this.setState({ site });

        const paths = [
            { name: "Toimipisteet", url: "/sites" },
            { name: site.name, url: "/products/" + siteId }
        ];

        this.setState({ paths });

        await this.updateProducts();
    }

    async updateProducts() {
        const products = await window.eng.getSiteProducts(this.state.site.id);
        console.log(products);
        products.sort(sortFunction("code"));
        this.setState({ productData: products });
    }

    handleSort = clickedColumn => () => {
        const { column, productData, direction } = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                customerData: productData.sort(sortFunction(clickedColumn)),
                direction: "ascending"
            });

            return;
        }

        this.setState({
            productData: productData.reverse(),
            direction: direction === "ascending" ? "descending" : "ascending"
        });
    };

    onEditClick = vehicle => {
        this.setState({ showEditModal: true, selectedVehicle: vehicle });
    };

    renderProducts() {
        const { productData } = this.state;

        return productData.map(product => {
            return (
                <Table.Row
                    key={product.id}
                    onClick={e => this.onEditClick(product, e)}
                >
                    <Table.Cell>{product.name}</Table.Cell>
                    <Table.Cell>{product.timberDiameter}</Table.Cell>
                    <Table.Cell>{product.code}</Table.Cell>
                    <Table.Cell>{product.timberClass}</Table.Cell>
                    <Table.Cell>{product.factorSummer}</Table.Cell>
                    <Table.Cell>{product.factorWinter}</Table.Cell>
                </Table.Row>
            );
        });
    }

    renderModal = () => {
        if (this.state.showAddModal) {
            return (
                <ProductModal
                    title="Uusi Tuote"
                    onDismiss={this.onModalDismiss}
                    method="ADD"
                    dataUpdate={this.onDataUpdate}
                    site={this.state.site}
                />
            );
        }
        if (this.state.showEditModal) {
            return (
                <ProductModal
                    title="Tuote"
                    onDismiss={this.onModalDismiss}
                    product={this.state.selectedProduct}
                    method="SAVE"
                    dataUpdate={this.onDataUpdate}
                    site={this.state.site}
                />
            );
        }
    };

    onAddClick = e => {
        this.setState({ showAddModal: true });
    };

    onEditClick = product => {
        this.setState({ showEditModal: true, selectedProduct: product });
    };

    onModalDismiss = () => {
        this.setState({ showAddModal: false, showEditModal: false });
    };

    onDataUpdate = async () => {
        this.setState({
            showAddModal: false,
            showEditModal: false,
            direction: null,
            column: null
        });
        await this.updateProducts();
    };

    renderAddButton() {
        return (
            <button
                onClick={this.onAddClick}
                className="ui active button RoolsGreenBG right floated"
            >
                <i className="plus circle icon" />
                Lisää Tuote
            </button>
        );
    }

    render() {
        const { column, direction } = this.state;
        return (
            <div className="PagePadding">
                {this.renderModal()}

                <div
                    className="FlexHorizontal"
                    style={{ alignItems: "center" }}
                >
                    <h2 style={{ flex: "1 1 auto", marginBottom: 0 }}>
                        Tuotteet
                    </h2>
                    {this.renderAddButton()}
                </div>

                <Table sortable celled collapsing selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                style={{ minWidth: "150px" }}
                                sorted={column === "name" ? direction : null}
                                onClick={this.handleSort("name")}
                            >
                                Nimi
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={
                                    column === "timberDiameter"
                                        ? direction
                                        : null
                                }
                                onClick={this.handleSort("timberDiameter")}
                            >
                                Läpimittaluokka
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={column === "code" ? direction : null}
                                onClick={this.handleSort("code")}
                            >
                                Läpimittaluokka
                                <br />
                                (tunniste)
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={
                                    column === "timberClass" ? direction : null
                                }
                                onClick={this.handleSort("timberClass")}
                            >
                                Tukkilaatu
                                <br />
                                (tunniste)
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={
                                    column === "factorSummer" ? direction : null
                                }
                                onClick={this.handleSort("factorSummer")}
                            >
                                Ilmakerroin kesä
                            </Table.HeaderCell>

                            <Table.HeaderCell
                                sorted={
                                    column === "factorWinter" ? direction : null
                                }
                                onClick={this.handleSort("factorWinter")}
                            >
                                Ilmakerroin talvi
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{this.renderProducts()}</Table.Body>
                </Table>
            </div>
        );
    }
}
