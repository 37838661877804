import React from "react";
import { Table } from "semantic-ui-react";
import SiteModal from "./SiteModal";
import CustomerModal from "./CustomerModal";
import CustomerRow from "./CustomerRow";
import { sortFunction } from "./cupla";

export default class SitesPage extends React.Component {
    state = {
        customers: [],
        sites: [],

        column2: null,
        direction2: null,

        selectedSite: null,
        selectedCustomer: null,

        showCustomerEditModal: false,
        showCustomerAddModal: false,
        showEditModal: false,
        showAddModal: false
    };

    render() {
        return (
            <div className="PagePadding">
                {this.renderModal()}
                <div
                    className="FlexHorizontal"
                    style={{ alignItems: "center" }}
                >
                    <h2 style={{ flex: "1 1 auto", marginBottom: 0 }}>
                        Toimipisteet
                    </h2>

                    <button
                        onClick={this.onAddClick}
                        className="ui active button RoolsGreenBG"
                    >
                        <i className="plus circle icon" />
                        Lisää Toimipiste
                    </button>

                    <button
                        style={{ marginLeft: "5px" }}
                        onClick={this.onCustomerAddClick}
                        className="ui active button RoolsGreenBG"
                    >
                        <i className="plus circle icon" />
                        Lisää Asiakas
                    </button>
                </div>

                <Table
                    sortable
                    celled
                    collapsing
                    style={{ overflow: "visible" }}
                >
                    <Table.Body>
                        {this.renderCustomers()}
                        {this.renderLeftovers()}
                    </Table.Body>
                </Table>
            </div>
        );
    }

    async componentDidMount() {
        await this.request();
    }

    async request() {
        const customers = await window.eng.getCustomers();
        this.setState({ customers });

        var sites = await window.eng.getSites();
        this.setState({ sites });
    }

    handleSort2 = clickedColumn => () => {
        const { column2, customers, direction2 } = this.state;

        if (column2 !== clickedColumn) {
            this.setState({
                column2: clickedColumn,
                customers: customers.sort(sortFunction(clickedColumn)),
                direction2: "ascending"
            });
            return;
        }

        this.setState({
            customers: customers.reverse(),
            direction2: direction2 === "ascending" ? "descending" : "ascending"
        });
    };

    onEditClick = site => {
        this.setState({ showEditModal: true, selectedSite: site });
    };

    onCustomerAddClick = e => {
        this.setState({ showCustomerAddModal: true });
    };

    onCustomerEditClick = customer => {
        this.setState({
            showCustomerEditModal: true,
            selectedCustomer: customer
        });
    };

    getCustomerSites(customer) {
        return this.state.sites.filter(
            site => site.customer && site.customer.id === customer.id
        );
    }

    renderCustomers() {
        let customers = this.state.customers;
        if (!window.eng.getCurrentUser()) {
            return null;
        }
        if (!window.eng.getCurrentUser().isAdmin()) {
            // Users which have permission to only specific sites can see only those customers
            customers = customers.filter(
                customer => this.getCustomerSites(customer).length
            );
        }
        return customers.map(customer => (
            <CustomerRow
                key={customer.id}
                onSiteEditClick={this.onEditClick}
                sites={this.getCustomerSites(customer)}
                customer={customer}
                onEditClick={this.onCustomerEditClick}
            />
        ));
    }

    renderLeftovers() {
        const customer = { id: 0, name: "MUUT" };
        var customerSites = this.state.sites.filter(site => !site.customer);
        return customerSites.length ? (
            <CustomerRow
                onSiteEditClick={this.onEditClick}
                sites={customerSites}
                customer={customer}
                onEditClick={this.onCustomerEditClick}
            />
        ) : null;
    }

    onModalDismiss = () => {
        this.setState({
            showAddModal: false,
            showEditModal: false,
            showCustomerAddModal: false,
            showCustomerEditModal: false
        });
    };

    onDataUpdate = async () => {
        this.setState({
            showAddModal: false,
            showEditModal: false,
            showCustomerAddModal: false,
            showCustomerEditModal: false,
            direction: null,
            column: null,
            direction2: null,
            column2: null
        });
        await this.request();
    };

    onAddClick = e => {
        this.setState({ showAddModal: true });
    };

    renderModal = () => {
        if (this.state.showAddModal) {
            return (
                <SiteModal
                    title="Uusi Toimipiste"
                    onDismiss={this.onModalDismiss}
                    method="ADD"
                    dataUpdate={this.onDataUpdate}
                />
            );
        }
        if (this.state.showEditModal) {
            return (
                <SiteModal
                    title="Toimipiste"
                    onDismiss={this.onModalDismiss}
                    site={this.state.selectedSite}
                    method="SAVE"
                    dataUpdate={this.onDataUpdate}
                />
            );
        }
        if (this.state.showCustomerAddModal) {
            return (
                <CustomerModal
                    title="Uusi Asiakas"
                    onDismiss={this.onModalDismiss}
                    method="ADD"
                    dataUpdate={this.onDataUpdate}
                />
            );
        }
        if (this.state.showCustomerEditModal) {
            return (
                <CustomerModal
                    title="Asiakas"
                    onDismiss={this.onModalDismiss}
                    customer={this.state.selectedCustomer}
                    method="SAVE"
                    dataUpdate={this.onDataUpdate}
                />
            );
        }
    };
}
