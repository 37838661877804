import React from "react";
import ReactDOM from "react-dom";
import Modal from "./Modal";
import { Checkbox } from "semantic-ui-react";

class UserModal extends React.Component {
    state = {
        id: null,
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        features: "D",
        site: 0,

        error: "",
        showConfirmation: false
    };

    componentDidMount() {
        if (this.props.user !== undefined) {
            this.setState({ id: this.props.user.id });

            if (this.props.user.email !== undefined)
                this.setState({ email: this.props.user.email });

            if (this.props.user.password !== undefined)
                this.setState({ password: this.props.user.password });

            if (this.props.user.firstName !== undefined)
                this.setState({ firstName: this.props.user.firstName });

            if (this.props.user.lastName !== undefined)
                this.setState({ lastName: this.props.user.lastName });

            if (this.props.user.features !== undefined) {
                this.setState({ features: this.props.user.features });
            }
        } else {
            this.setState({ site: this.props.site });
        }
        document.addEventListener("keydown", this.escFunction, false);
        console.log(this.props.site);
    }

    escFunction = event => {
        if (event.keyCode === 27) this.props.onDismiss();
    };

    handleChange = (e, { value }) => this.setState({ features: value });

    checkError = () => {
        const email = this.state.email;
        if (email.length >= 3) {
            var i = email.indexOf("@");
            if (i > 0 && i < email.length - 1) {
                //OK
                this.setState({ error: "" });
            } else {
                //INVALID
                this.setState({ error: "Sähköposti ei kelpaa." });
            }
        } else {
            this.setState({ error: "Anna toimiva sähköposti." });
        }
    };

    // Render the middle part of the modal.
    renderContent() {
        return (
            <form className="ui form">
                <div className="field">
                    <label>Sähköposti:</label>
                    <input
                        onChange={e => this.setState({ email: e.target.value })}
                        type="text"
                        value={this.state.email}
                    />
                </div>
                <div className="field">
                    <label>Salasana:</label>
                    <input
                        onChange={e =>
                            this.setState({ password: e.target.value })
                        }
                        type="text"
                        placeholder="(ei muuteta)"
                        value={this.state.password}
                    />
                </div>
                <div className="field">
                    <label>Etunimi:</label>
                    <input
                        onChange={e =>
                            this.setState({ firstName: e.target.value })
                        }
                        type="text"
                        value={this.state.firstName}
                    />
                </div>
                <div className="field">
                    <label>Sukunimi:</label>
                    <input
                        onChange={e =>
                            this.setState({ lastName: e.target.value })
                        }
                        type="text"
                        value={this.state.lastName}
                    />
                </div>
                <Checkbox
                    style={{ marginRight: "10px" }}
                    name="checkboxRadioGroup"
                    checked={this.state.features === "D"}
                    onChange={this.handleChange}
                    value="D"
                    type="checkbox"
                    className="hidden"
                    tabIndex="0"
                    label="Kuljettaja"
                />
                <Checkbox
                    style={{ marginRight: "10px" }}
                    name="checkboxRadioGroup"
                    checked={this.state.features === "M"}
                    onChange={this.handleChange}
                    value="M"
                    type="checkbox"
                    className="hidden"
                    tabIndex="0"
                    label="Pääkäyttäjä"
                />
                <Checkbox
                    name="checkboxRadioGroup"
                    checked={this.state.features === "A"}
                    onChange={this.handleChange}
                    value="A"
                    type="checkbox"
                    className="hidden"
                    tabIndex="0"
                    label="Ylläpitäjä"
                />

                <div className="errorMessage text-center">
                    {this.state.error}{" "}
                </div>
            </form>
        );
    }

    // Delete confirmation modal stuff //
    onDismiss = () => {
        this.setState({ showConfirmation: false });
    };

    confirmContent() {
        return <>Haluatko varmasti poistaa käyttäjän?</>;
    }

    confirmActions() {
        return (
            <>
                <button
                    onClick={this.onDeleteConfirm}
                    className="ui button red"
                >
                    Kyllä
                </button>
                <button onClick={this.onDismiss} className="ui button">
                    Peruuta
                </button>
            </>
        );
    }

    onDeleteConfirm = e => {
        this.deleteUser(this.state.id);
        this.props.dataUpdate();
    };

    // Button click calls and API calls.
    onDeleteClick = e => {
        this.setState({ showConfirmation: true });
    };

    deleteUser = async id => {
        await window.eng.deleteUser(id);
        await this.props.dataUpdate();
        await this.props.onDismiss();
    };

    onSaveClick = async e => {
        await this.checkError();
        console.log(this.state.error);
        if (this.state.error === "") {
            await window.eng.setUser(this.state);
            await this.props.dataUpdate();
        }
    };

    // Only add delete button if the selected user is not the current user.
    renderDelete() {
        if (
            this.state.id !== window.eng.user.id &&
            this.props.method === "SAVE"
        ) {
            return (
                <>
                    <button
                        onClick={this.onDeleteClick}
                        className="ui button red floatLeft"
                    >
                        Poista
                    </button>
                </>
            );
        }
    }

    // Render bottom part of the modal (buttons).
    renderActions() {
        return (
            <>
                {this.renderDelete()}
                <button onClick={this.props.onDismiss} className="ui button">
                    Peruuta
                </button>
                <button
                    onClick={this.onSaveClick}
                    className="ui button RoolsGreenBG"
                >
                    Tallenna
                </button>
            </>
        );
    }

    renderConfirmationModal = () => {
        if (this.state.showConfirmation) {
            return (
                <Modal
                    onDismiss={this.onDismiss}
                    title=""
                    content={this.confirmContent()}
                    actions={this.confirmActions()}
                />
            );
        }
    };

    // Main render.
    render() {
        return ReactDOM.createPortal(
            <div
                onClick={this.props.onDismiss}
                className="ui dimmer modals visible active"
            >
                <div
                    style={{ position: "relative" }}
                    className="ui standard modal visible active"
                    onClick={e => e.stopPropagation()}
                >
                    {this.renderConfirmationModal()}
                    <div className="header">{this.props.title}</div>
                    <div className="content">{this.renderContent()}</div>
                    <div className="actions">{this.renderActions()}</div>
                </div>
            </div>,
            document.querySelector("#modal")
        );
    }
}

export default UserModal;
