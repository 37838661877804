import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Dropdown } from "semantic-ui-react";
import Modal from "./Modal";
import { parseValidNumber } from "./cupla";

export default class SiteModal extends Component {
    state = {
        id: 0,
        name: "",
        lockersCount: 0,
        lockerSize: 0,
        storagesCount: 0,
        storageSize: 0,
        customer: 0,
        colorLimit1: 50,
        colorLimit2: 80,
        orientation: 0,
        showConfirmation: false,
        customerOptions: []
    };

    async componentDidMount() {
        if (this.props.site !== undefined) {
            this.setState({ id: this.props.site.id });

            if (this.props.site.name !== undefined)
                this.setState({ name: this.props.site.name });

            if (this.props.site.lockersCount !== undefined)
                this.setState({ lockersCount: this.props.site.lockersCount });

            if (this.props.site.lockerSize !== undefined)
                this.setState({ lockerSize: this.props.site.lockerSize });

            if (this.props.site.storagesCount !== undefined) {
                this.setState({
                    storagesCount: this.props.site.storagesCount
                });
            }

            if (this.props.site.storageSize !== undefined) {
                this.setState({ storageSize: this.props.site.storageSize });
            }

            if (this.props.site.customer !== null) {
                this.setState({ customer: this.props.site.customer.id });
            }

            if (this.props.site.lockerSize !== null) {
                this.setState({ lockerSize: this.props.site.lockerSize });
            }

            if (this.props.site.colorLimit1 !== null) {
                this.setState({ colorLimit1: this.props.site.colorLimit1 });
            }

            if (this.props.site.colorLimit2 !== null) {
                this.setState({ colorLimit2: this.props.site.colorLimit2 });
            }

            this.setState({
                orientation: parseValidNumber(this.props.site.orientation)
            });
        }
        const customers = await window.eng.getCustomers();
        var customerOptions = [];
        customerOptions.push({ value: 0, key: "", text: "" });
        for (let customer of customers) {
            customerOptions.push({
                value: customer.id,
                key: customer.id,
                text: customer.name
            });
        }
        this.setState({ customerOptions });
        document.addEventListener("keydown", this.escFunction, false);
    }

    escFunction = event => {
        if (event.keyCode === 27) this.props.onDismiss();
    };

    handleChange = (e, { value }) => {
        this.setState({ customer: value });
    };

    renderContent() {
        return (
            <form onKeyDown={this.handleKeyPress} className="ui form">
                <div className="PropertyRow">
                    <div>Nimi:</div>
                    <input
                        onChange={e => this.setState({ name: e.target.value })}
                        type="text"
                        value={this.state.name}
                    />
                </div>
                <div className="PropertyRow">
                    <div>Asiakas:</div>
                    <Dropdown
                        placeholder="Valitse Asiakas"
                        selection
                        options={this.state.customerOptions}
                        value={this.state.customer}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="PropertyRow">
                    <div>Lokerot:</div>
                    <Dropdown
                        selection
                        options={[
                            {
                                value: 0,
                                key: "0",
                                text: "Alkuperäinen"
                            },
                            {
                                value: 1,
                                key: "1",
                                text: "Parittomat alhaalla + Aloitus oikealta"
                            },
                            {
                                value: 2,
                                key: "2",
                                text: "Parittomat ylhäällä + Aloitus oikealta"
                            },
                            {
                                value: 3,
                                key: "3",
                                text: "Parittomat alhaalla + Aloitus vasemmalta"
                            },
                            {
                                value: 4,
                                key: "4",
                                text: "Parittomat ylhäällä + Aloitus vasemmalta"
                            }
                        ]}
                        value={this.state.orientation}
                        onChange={(e, { value }) =>
                            this.setState({ orientation: value })
                        }
                    />
                </div>
                <div className="PropertyRow">
                    <div>Lokerot (kpl):</div>
                    <input
                        onChange={e =>
                            this.setState({
                                lockersCount: parseValidNumber(e.target.value)
                            })
                        }
                        type="number"
                        value={this.state.lockersCount}
                    />
                </div>
                <div className="PropertyRow">
                    <div>Lokeron koko (m2):</div>
                    <input
                        onChange={e =>
                            this.setState({
                                lockerSize: parseValidNumber(e.target.value)
                            })
                        }
                        type="number"
                        value={this.state.lockerSize}
                    />
                </div>
                <div className="PropertyRow">
                    <div>Varastot (kpl):</div>
                    <input
                        onChange={e =>
                            this.setState({
                                storagesCount: parseValidNumber(e.target.value)
                            })
                        }
                        type="number"
                        value={this.state.storagesCount}
                    />
                </div>
                <div className="PropertyRow">
                    <div>Varaston koko (m2):</div>
                    <input
                        onChange={e =>
                            this.setState({
                                storageSize: parseValidNumber(e.target.value)
                            })
                        }
                        type="number"
                        value={this.state.storageSize}
                    />
                </div>
                <div className="PropertyRow">
                    <div>Värirajat:</div>
                    <div
                        className="FlexHorizontal"
                        style={{ alignItems: "center" }}
                    >
                        <div
                            style={{
                                flex: "0 0 auto",
                                width: "30px",
                                height: "30px",
                                background: "#fbbd08"
                            }}
                        />
                        <input
                            style={{
                                width: "auto",
                                marginLeft: "10px",
                                marginRight: "10px"
                            }}
                            onChange={e =>
                                this.setState({
                                    colorLimit1: parseValidNumber(
                                        e.target.value
                                    )
                                })
                            }
                            type="number"
                            value={this.state.colorLimit1}
                        />
                        <div
                            style={{
                                flex: "0 0 auto",
                                width: "30px",
                                height: "30px",
                                background: "#db2828"
                            }}
                        />
                        <input
                            style={{ width: "auto", marginLeft: "10px" }}
                            onChange={e =>
                                this.setState({
                                    colorLimit2: parseValidNumber(
                                        e.target.value
                                    )
                                })
                            }
                            type="number"
                            value={this.state.colorLimit2}
                        />
                    </div>
                </div>
                <div className="errorMessage text-center">
                    {this.state.error}{" "}
                </div>
            </form>
        );
    }

    onDismiss = () => {
        this.setState({ showConfirmation: false });
    };

    confirmContent() {
        return <>Haluatko varmasti poistaa toimipisteen?</>;
    }

    confirmActions() {
        return (
            <>
                <button
                    onClick={this.onDeleteConfirm}
                    className="ui button red"
                >
                    Kyllä
                </button>
                <button onClick={this.onDismiss} className="ui button">
                    Peruuta
                </button>
            </>
        );
    }

    onDeleteConfirm = async e => {
        await window.eng.deleteSite(this.state.id);
        this.props.dataUpdate();
        await this.props.onDismiss();
    };

    onDeleteClick = e => {
        this.setState({ showConfirmation: true });
    };

    async onSaveClick() {
        const {
            id,
            name,
            lockersCount,
            lockerSize,
            storagesCount,
            storageSize,
            colorLimit1,
            colorLimit2,
            orientation,
            customer
        } = this.state;
        const site = {
            id,
            name,
            lockersCount,
            lockerSize,
            storagesCount,
            storageSize,
            colorLimit1,
            colorLimit2,
            orientation,
            customer
        };
        await window.eng.setSite(site);
        await this.props.dataUpdate();
    }

    renderActions() {
        return (
            <>
                {this.renderDelete()}
                <button onClick={this.props.onDismiss} className="ui button">
                    Peruuta
                </button>
                <button
                    onClick={() => this.onSaveClick()}
                    className="ui button green"
                >
                    Tallenna
                </button>
            </>
        );
    }

    renderDelete() {
        if (this.props.method === "SAVE") {
            return (
                <>
                    <button
                        onClick={this.onDeleteClick}
                        className="ui button red floatLeft"
                    >
                        Poista
                    </button>
                </>
            );
        }
    }

    renderConfirmationModal = () => {
        if (this.state.showConfirmation) {
            return (
                <Modal
                    onDismiss={this.onDismiss}
                    title=""
                    content={this.confirmContent()}
                    actions={this.confirmActions()}
                />
            );
        }
    };

    render() {
        return ReactDOM.createPortal(
            <div
                onClick={this.props.onDismiss}
                className="ui dimmer modals visible active"
            >
                <div
                    style={{ position: "relative" }}
                    className="ui standard modal visible active"
                    onClick={e => e.stopPropagation()}
                >
                    {this.renderConfirmationModal()}
                    <div className="header">{this.props.title}</div>
                    <div className="content">{this.renderContent()}</div>
                    <div className="actions">{this.renderActions()}</div>
                </div>
            </div>,
            document.querySelector("#modal")
        );
    }
}
