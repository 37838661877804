import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { sortFunction } from "./cupla";

export default class CustomerRow extends Component {
    state = {
        sites: [],
        showCustomers: true,
        column: null,
        direction: null
    };

    render() {
        const customer = this.props.customer;
        const iconClass = this.state.showCustomers
            ? "ui icon minus"
            : "ui icon plus";
        return (
            <>
                <Table.Row>
                    <Table.Cell onClick={this.onOpenClick} collapsing>
                        <i className={iconClass} />
                    </Table.Cell>
                    {this.renderName(customer)}
                </Table.Row>
                <Table.Row>{this.renderView()}</Table.Row>
            </>
        );
    }

    componentDidMount() {
        this.setState({ sites: this.props.sites });
    }

    componentDidUpdate(prevProps) {
        if (this.props.sites !== prevProps.sites) {
            this.setState({ sites: this.props.sites });
        }
    }

    handleSort = clickedColumn => () => {
        const { column, sites, direction } = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                sites: sites.sort(sortFunction(clickedColumn)),
                direction: "ascending"
            });
            return;
        }

        this.setState({
            sites: sites.reverse(),
            direction: direction === "ascending" ? "descending" : "ascending"
        });
    };

    renderSites() {
        const { sites } = this.state;
        return sites.map(site => {
            return (
                <Table.Row key={site.id}>
                    <Table.Cell
                        style={{ width: "100%", verticalAlign: "middle" }}
                    >
                        {site.name}
                    </Table.Cell>
                    <Table.Cell>
                        <div className="ui icon buttons">
                            <Link to={`/sites/${site.id}`}>
                                <button
                                    className="ui basic button"
                                    data-tooltip="Tilannekuva"
                                >
                                    <i className="eye icon" />
                                </button>
                            </Link>
                            {window.eng.user.isAdmin() ? (
                                <Link to={`/sites/${site.id}/map`}>
                                    <button
                                        className="ui basic button"
                                        data-tooltip="Kartta"
                                    >
                                        <i className="map marker alternate icon" />
                                    </button>
                                </Link>
                            ) : null}
                            <Link to={`/products/${site.id}`}>
                                <button
                                    className="ui basic button"
                                    data-tooltip="Tuotteet"
                                >
                                    <i className="box icon" />
                                </button>
                            </Link>
                            <Link to={`/vehicles/${site.id}`}>
                                <button
                                    className="ui basic button"
                                    data-tooltip="Työkoneet"
                                >
                                    <i className="truck icon" />
                                </button>
                            </Link>
                            <Link to={`/users/${site.id}`}>
                                <button
                                    className="ui basic button"
                                    data-tooltip="Käyttäjät"
                                >
                                    <i className="users icon" />
                                </button>
                            </Link>
                            <a>
                                <button
                                    onClick={e => this.onSiteEditClick(site)}
                                    className="ui basic button"
                                    data-tooltip="Asetukset"
                                >
                                    <i className="wrench icon" />
                                </button>
                            </a>
                        </div>
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    renderView() {
        if (this.state.showCustomers) {
            return (
                <td colSpan="2" className="ui content" style={{ padding: 0 }}>
                    <Table
                        selectable
                        collapsing
                        celled
                        sortable
                        style={{
                            border: "none",
                            width: "100%",
                            overflow: "visible"
                        }}
                    >
                        <Table.Body>{this.renderSites()}</Table.Body>
                    </Table>
                </td>
            );
        }
    }

    onSiteEditClick(site) {
        this.props.onSiteEditClick(site);
    }

    onEditClick() {
        this.props.onEditClick(this.props.customer);
    }

    onOpenClick = async () => {
        const { showCustomers } = this.state;

        if (!showCustomers) {
            this.setState({ showCustomers: true });
        } else if (showCustomers) {
            this.setState({ showCustomers: false });
        }
    };

    renderName(customer) {
        return (
            <Table.Cell
                onClick={
                    customer.id ? e => this.onEditClick(customer, e) : null
                }
                style={{ minWidth: "600px" }}
            >
                {customer.name}
            </Table.Cell>
        );
    }
}
