import React, { Component } from "react";
import StorageView from "./StorageView";
import StorageModal from "./StorageModal";

export default class StoragesView extends Component {
    elem = null;

    state = {
        selectedStorageStatus: null
    };

    onEditClick = storageStatus => {
        if (window.eng.user) {
            const features = window.eng.user.features;
            if (features === "A" || features === "S" || features === "M") {
                this.setState({
                    showEditModal: true,
                    selectedStorageStatus: storageStatus
                });
            }
        }
    };

    onDataUpdate() {
        this.setState({ showEditModal: false, direction: null, column: null });
        this.updateData();
    }

    onModalDismiss = () => {
        this.setState({ showEditModal: false });
    };

    renderModal() {
        if (this.state.showEditModal) {
            return (
                <StorageModal
                    title={
                        "VARASTO " +
                        this.state.selectedStorageStatus.storage.number
                    }
                    onDismiss={this.onModalDismiss}
                    storageStatus={this.state.selectedStorageStatus}
                    method="SAVE"
                    dataUpdate={this.props.updateSiteStatus}
                    site={this.props.site}
                />
            );
        }
    }

    render() {
        if (
            !this.props.siteStatus ||
            !this.props.siteStatus.storages ||
            this.props.siteStatus.storages.length === 0
        ) {
            return (
                <div
                    ref={e => (this.elem = e)}
                    className="ui active loader"
                ></div>
            );
        }

        let oddStorages = this.props.siteStatus.storages.filter(
            st => st.storage.number % 2
        );
        let evenStorages = this.props.siteStatus.storages.filter(
            st => !oddStorages.includes(st)
        );
        let storages = null;
        let right = false;
        let reverse = false;
        if (this.props.isTop) {
            // Top storages
            reverse = true;
            if (this.props.site.orientation === 2) {
                // 2 == Odd top + right start
                storages = oddStorages;
                right = true;
            } else if (this.props.site.orientation === 4) {
                // 4 == Odd top + left start
                storages = oddStorages;
            } else if (this.props.site.orientation === 1) {
                // 1 == Even top + right start
                storages = evenStorages;
                right = true;
            } else if (this.props.site.orientation === 3) {
                // 3 == Even top + left start
                storages = evenStorages;
            } else {
                // 0 == Original
                storages = oddStorages;
                reverse = false;
            }
        } else if (this.props.isBottom) {
            // Bottom storages
            if (this.props.site.orientation === 2) {
                // 2 == Even bottom + right start
                storages = evenStorages;
                right = true;
            } else if (this.props.site.orientation === 4) {
                // 4 == Even bottom + left start
                storages = evenStorages;
            } else if (this.props.site.orientation === 1) {
                // 1 == Odd bottom + right start
                storages = oddStorages;
                right = true;
            } else if (this.props.site.orientation === 3) {
                // 3 == Odd bottom + left start
                storages = oddStorages;
            } else {
                // 0 == Original
                storages = evenStorages;
                reverse = false;
            }
        }
        return (
            <div
                ref={e => (this.elem = e)}
                style={{
                    margin: "20px 10px",
                    display: "flex",
                    flexDirection: right ? "row-reverse" : "row",
                    flexWrap: reverse ? "wrap-reverse" : "wrap"
                }}
            >
                {this.renderModal()}
                {storages.map(st => (
                    <StorageView
                        key={st.storage.id}
                        storage={st.storage}
                        storageStatus={st}
                        onClick={e => this.onEditClick(st, e)}
                    />
                ))}
            </div>
        );
    }
}
