import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import VehicleModal from "./VehicleModal";
import { parseValidNumber, sortFunction } from "./cupla";

export default class VehiclesPage extends Component {
    state = {
        site: null,

        vehicles: [],
        column: null,
        direction: null,
        paths: [],

        selectedVehicle: null,
        showEditModal: false,
        showAddModal: false
    };

    async componentDidMount() {
        const siteId = parseValidNumber(this.props.match.params.id);
        const site = await window.eng.getSite(siteId);
        this.setState({ site });

        await this.updateVehicles();

        const paths = [
            { name: "Toimipisteet", url: "/sites" },
            {
                name: `Työkoneet - ${site.name}`,
                url: `/vehicles/${siteId}`
            }
        ];
        this.setState({ paths });
    }

    async updateVehicles() {
        const vehicles = await window.eng.getSiteVehicles(this.state.site.id);
        console.log(vehicles);
        this.setState({ vehicles });
    }

    handleSort = clickedColumn => () => {
        const { column, vehicles, direction } = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                vehicles: vehicles.sort(sortFunction(clickedColumn)),
                direction: "ascending"
            });

            return;
        }

        this.setState({
            vehicles: vehicles.reverse(),
            direction: direction === "ascending" ? "descending" : "ascending"
        });
    };

    renderType(type) {
        var name = "";
        if (type === 1) {
            name = "Kurottaja";
        } else if (type === 2) {
            name = "Pyöräkuormaaja";
        } else if (type === 3) {
            name = "Riippupihtipyöräkuormaaja";
        }
        return name;
    }

    renderVehicles() {
        const { vehicles } = this.state;
        return vehicles.map(vehicle => {
            return (
                <Table.Row
                    key={vehicle.id}
                    onClick={e => this.onEditClick(vehicle, e)}
                >
                    <Table.Cell>{vehicle.name}</Table.Cell>
                    <Table.Cell>{this.renderType(vehicle.type)}</Table.Cell>
                    <Table.Cell>{vehicle.model}</Table.Cell>
                    <Table.Cell>{vehicle.size}</Table.Cell>
                </Table.Row>
            );
        });
    }

    onAddClick = e => {
        this.setState({ showAddModal: true });
    };

    onEditClick = vehicle => {
        this.setState({ showEditModal: true, selectedVehicle: vehicle });
    };

    onModalDismiss = () => {
        this.setState({ showAddModal: false, showEditModal: false });
    };

    onDataUpdate = async () => {
        this.setState({
            showAddModal: false,
            showEditModal: false,
            direction: null,
            column: null
        });
        await this.updateVehicles();
    };

    renderModal = () => {
        if (this.state.showAddModal) {
            return (
                <VehicleModal
                    title="Uusi Työkone"
                    onDismiss={this.onModalDismiss}
                    method="ADD"
                    dataUpdate={this.onDataUpdate}
                    site={this.state.site}
                />
            );
        }
        if (this.state.showEditModal) {
            return (
                <VehicleModal
                    title="Työkone"
                    onDismiss={this.onModalDismiss}
                    vehicle={this.state.selectedVehicle}
                    method="SAVE"
                    dataUpdate={this.onDataUpdate}
                    site={this.state.site}
                />
            );
        }
    };

    renderAddButton() {
        return (
            <button
                onClick={this.onAddClick}
                className="ui active button RoolsGreenBG right floated"
            >
                <i className="plus circle icon" />
                Lisää Työkone
            </button>
        );
    }

    render() {
        const { column, direction } = this.state;
        return (
            <div className="PagePadding">
                {this.renderModal()}

                <div
                    className="FlexHorizontal"
                    style={{ alignItems: "center" }}
                >
                    <h2 style={{ flex: "1 1 auto", marginBottom: 0 }}>
                        Työkoneet
                    </h2>
                    {this.renderAddButton()}
                </div>

                <Table selectable sortable celled collapsing compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={column === "name" ? direction : null}
                                onClick={this.handleSort("name")}
                            >
                                Nimi
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={column === "type" ? direction : null}
                                onClick={this.handleSort("type")}
                            >
                                Tyyppi
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={column === "model" ? direction : null}
                                onClick={this.handleSort("model")}
                            >
                                Malli
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={
                                    column === "grabSize" ? direction : null
                                }
                                onClick={this.handleSort("grabsize")}
                            >
                                Koko (m2)
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{this.renderVehicles()}</Table.Body>
                </Table>
            </div>
        );
    }
}
