import React, { Component } from "react";
import { nbsp } from "./cupla";

export default class StorageView extends Component {
    render() {
        return (
            <div
                className="Clickable"
                style={{
                    width: "80px",
                    height: "80px",
                    margin: "0 2px 2px 0",
                    borderRadius: "0px",
                    background: "#e0e1e2",
                    color: "rgba(0,0,0,.6)",
                    padding: "8px",
                    textAlign: "center",
                    fontWeight: "bold",
                    border: this.props.storageStatus.batchAtStorage
                        ? "2px solid black"
                        : "",
                    ...this.props.style
                }}
                onClick={this.props.onClick}
            >
                <div>{window.eng.getStorageName(this.props.storage)}</div>
                <div>
                    {window.eng.getProductName(
                        this.props.storageStatus.product
                    )}
                </div>
                <div>
                    {this.props.storageStatus.count
                        ? this.props.storageStatus.count
                        : nbsp()}
                </div>
            </div>
        );
    }
}
