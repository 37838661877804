import React, { Component } from "react";
import ReactDOM from "react-dom";
import Modal from "./Modal";
import { parseValidNumber } from "./cupla";

export default class ProductModal extends Component {
    state = {
        id: 0,
        site: 0,
        code: "",
        name: "",
        timberDiameter: 0,
        timberClass: "",
        factorWinter: 1,
        factorSummer: 1,

        showConfirmation: false
    };

    async componentDidMount() {
        if (this.props.product !== undefined) {
            this.setState({ id: this.props.product.id });

            if (this.props.product.code)
                this.setState({ code: this.props.product.code });

            if (this.props.product.name)
                this.setState({ name: this.props.product.name });

            if (this.props.product.timberDiameter !== undefined)
                this.setState({
                    timberDiameter: this.props.product.timberDiameter
                });

            if (this.props.product.timberClass !== undefined)
                this.setState({ timberClass: this.props.product.timberClass });

            if (this.props.product.site !== undefined)
                this.setState({ site: this.props.product.site });

            if (this.props.product.factorWinter !== null) {
                this.setState({
                    factorWinter: this.props.product.factorWinter
                });
            }

            if (this.props.product.factorSummer !== null) {
                this.setState({
                    factorSummer: this.props.product.factorSummer
                });
            }
        } else {
            this.setState({ site: this.props.site.id });
        }
        document.addEventListener("keydown", this.escFunction, false);
    }

    escFunction = event => {
        if (event.keyCode === 27) this.props.onDismiss();
    };

    renderContent() {
        return (
            <form className="ui form">
                <div className="field">
                    <label>Nimi:</label>
                    <input
                        onChange={e => this.setState({ name: e.target.value })}
                        type="text"
                        value={this.state.name}
                    />
                </div>
                <div className="field">
                    <label>Läpimittaluokka:</label>
                    <input
                        onChange={e =>
                            this.setState({
                                timberDiameter: parseValidNumber(e.target.value)
                            })
                        }
                        type="number"
                        value={this.state.timberDiameter}
                    />
                </div>
                <div className="field">
                    <label>Läpimittaluokka (tunniste):</label>
                    <input
                        onChange={e => this.setState({ code: e.target.value })}
                        type="text"
                        value={this.state.code}
                    />
                </div>
                <div className="field">
                    <label>Tukkilaatu (tunniste):</label>
                    <input
                        onChange={e =>
                            this.setState({ timberClass: e.target.value })
                        }
                        type="text"
                        value={this.state.timberClass}
                    />
                </div>
                <div className="field">
                    <label>Ilmakerroin kesä:</label>
                    <input
                        onChange={e =>
                            this.setState({
                                factorSummer: parseValidNumber(e.target.value)
                            })
                        }
                        type="number"
                        value={this.state.factorSummer}
                    />
                </div>
                <div className="field">
                    <label>Ilmakerroin talvi:</label>
                    <input
                        onChange={e =>
                            this.setState({
                                factorWinter: parseValidNumber(e.target.value)
                            })
                        }
                        type="number"
                        value={this.state.factorWinter}
                    />
                </div>
            </form>
        );
    }

    // Delete confirmation modal stuff //
    onDismiss = () => {
        this.setState({ showConfirmation: false });
    };

    confirmContent() {
        return <>Haluatko varmasti poistaa tuotteen?</>;
    }

    confirmActions() {
        return (
            <>
                <button
                    onClick={this.onDeleteConfirm}
                    className="ui button negative"
                >
                    Kyllä
                </button>
                <button onClick={this.onDismiss} className="ui button ">
                    Peruuta
                </button>
            </>
        );
    }

    onDeleteConfirm = e => {
        this.deleteProduct(this.state.id);
    };

    // Button click calls and API calls.
    onDeleteClick = e => {
        this.setState({ showConfirmation: true });
    };

    deleteProduct = async id => {
        await window.eng.deleteProduct(id);
        this.props.dataUpdate();
        await this.props.onDismiss();
    };

    onSaveClick = async e => {
        await window.eng.setProduct(this.state);
        await this.props.dataUpdate();
    };

    renderActions() {
        return (
            <>
                {this.renderDelete()}
                <button onClick={this.props.onDismiss} className="ui button">
                    Peruuta
                </button>
                <button
                    onClick={this.onSaveClick}
                    className="ui button RoolsGreenBG"
                >
                    Tallenna
                </button>
            </>
        );
    }

    renderDelete() {
        if (this.props.method === "SAVE") {
            return (
                <>
                    <button
                        onClick={this.onDeleteClick}
                        className="ui button red floatLeft"
                    >
                        Poista
                    </button>
                </>
            );
        }
    }

    renderConfirmationModal = () => {
        if (this.state.showConfirmation) {
            return (
                <Modal
                    onDismiss={this.onDismiss}
                    title=""
                    content={this.confirmContent()}
                    actions={this.confirmActions()}
                />
            );
        }
    };

    render() {
        return ReactDOM.createPortal(
            <div
                onClick={this.props.onDismiss}
                className="ui dimmer modals visible active"
            >
                <div
                    style={{ position: "relative" }}
                    className="ui standard modal visible active"
                    onClick={e => e.stopPropagation()}
                >
                    {this.renderConfirmationModal()}
                    <div className="header">{this.props.title}</div>
                    <div className="content">{this.renderContent()}</div>
                    <div className="actions">{this.renderActions()}</div>
                </div>
            </div>,
            document.querySelector("#modal")
        );
    }
}
