import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Dropdown } from "semantic-ui-react";
import Modal from "./Modal";
import { parseValidNumber } from "./cupla";

export default class VehicleModal extends Component {
    state = {
        id: 0,
        site: 0,
        name: "",
        type: 1,
        model: "",
        size: 0,

        showConfirmation: false
    };

    async componentDidMount() {
        if (this.props.vehicle !== undefined) {
            this.setState({ id: this.props.vehicle.id });

            if (this.props.vehicle.name !== undefined)
                this.setState({ name: this.props.vehicle.name });

            if (this.props.vehicle.site !== undefined)
                this.setState({ site: this.props.site });

            if (this.props.vehicle.type !== null) {
                this.setState({ type: this.props.vehicle.type });
            }
            if (this.props.vehicle.model !== null) {
                this.setState({ model: this.props.vehicle.model });
            }

            if (this.props.vehicle.size !== undefined)
                this.setState({ size: this.props.vehicle.size });
        } else {
            this.setState({ site: this.props.site.id });
        }
        document.addEventListener("keydown", this.escFunction, false);
    }

    escFunction = event => {
        if (event.keyCode === 27) this.props.onDismiss();
    };

    renderContent() {
        return (
            <form className="ui form">
                <div className="field">
                    <label>Nimi:</label>
                    <input
                        onChange={e => this.setState({ name: e.target.value })}
                        type="text"
                        value={this.state.name}
                    />
                </div>
                <div className="field">
                    <label>Tyyppi:</label>
                    <Dropdown
                        placeholder="Tyyppi"
                        selection
                        options={[
                            { value: 1, text: "Kurottaja" },
                            { value: 2, text: "Pyöräkuormaaja" },
                            { value: 3, text: "Riippupihtipyöräkuormaaja" }
                        ]}
                        value={this.state.type}
                        onChange={(e, { value }) =>
                            this.setState({ type: value })
                        }
                    />
                </div>
                <div className="field">
                    <label>Malli:</label>
                    <input
                        onChange={e => this.setState({ model: e.target.value })}
                        type="text"
                        value={this.state.model}
                    />
                </div>
                <div className="field">
                    <label>Koko (m2):</label>
                    <input
                        onChange={e =>
                            this.setState({
                                size: parseValidNumber(e.target.value)
                            })
                        }
                        type="number"
                        value={this.state.size}
                    />
                </div>
            </form>
        );
    }

    // Delete confirmation modal stuff //
    onDismiss = () => {
        this.setState({ showConfirmation: false });
    };

    confirmContent() {
        return <>Haluatko varmasti poistaa työkoneen?</>;
    }

    confirmActions() {
        return (
            <>
                <button
                    onClick={this.onDeleteConfirm}
                    className="ui button negative"
                >
                    Kyllä
                </button>
                <button onClick={this.onDismiss} className="ui button ">
                    Peruuta
                </button>
            </>
        );
    }

    onDeleteConfirm = e => {
        this.deleteVehicle(this.state.id);
    };

    // Button click calls and API calls.
    onDeleteClick = e => {
        this.setState({ showConfirmation: true });
    };

    deleteVehicle = async id => {
        await window.eng.deleteVehicle(id);
        this.props.dataUpdate();
        await this.props.onDismiss();
    };

    onSaveClick = async e => {
        await window.eng.setVehicle(this.state);
        await this.props.dataUpdate();
    };

    // Render bottom part of the modal (buttons).
    renderActions() {
        return (
            <>
                {this.renderDelete()}
                <button onClick={this.props.onDismiss} className="ui button">
                    Peruuta
                </button>
                <button
                    onClick={this.onSaveClick}
                    className="ui button RoolsGreenBG"
                >
                    Tallenna
                </button>
            </>
        );
    }

    renderDelete() {
        if (this.props.method === "SAVE") {
            return (
                <>
                    <button
                        onClick={this.onDeleteClick}
                        className="ui button red floatLeft"
                    >
                        Poista
                    </button>
                </>
            );
        }
    }

    renderConfirmationModal = () => {
        if (this.state.showConfirmation) {
            return (
                <Modal
                    onDismiss={this.onDismiss}
                    title=""
                    content={this.confirmContent()}
                    actions={this.confirmActions()}
                />
            );
        }
    };

    // Main render.
    render() {
        return ReactDOM.createPortal(
            <div
                onClick={this.props.onDismiss}
                className="ui dimmer modals visible active"
            >
                <div
                    style={{ position: "relative" }}
                    className="ui standard modal visible active"
                    onClick={e => e.stopPropagation()}
                >
                    {this.renderConfirmationModal()}
                    <div className="header">{this.props.title}</div>
                    <div className="content">{this.renderContent()}</div>
                    <div className="actions">{this.renderActions()}</div>
                </div>
            </div>,
            document.querySelector("#modal")
        );
    }
}
