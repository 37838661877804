import React, { Component } from "react";
import Modal from "./Modal";
import { Dropdown } from "semantic-ui-react";
import { nbsp } from "./cupla";
import LockerDialog from "./LockerDialog";
import Dialog from "./Dialog";
import VehicleImage from "./img/vehicle.png";

export default class LockerView extends Component {
    state = {
        busy: false,
        showModal: false,
        showInfoBlock: false,
        closeTime: 5000,
        storages: [],
        selectedStorageId: 0,
        emptyStorages: [],
        selectedEmptyStorage: 0
    };

    componentDidMount() {
        if (this.props.lockerStatus.percentage >= 80) {
            this.showInformation();
        }
        this.updateStorages();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.lockerStatus !== this.props.lockerStatus) {
            if (this.props.lockerStatus.percentage >= 80) {
                this.showInformation();
            }
        }
        if (prevProps.storageStatuses !== this.props.storageStatuses) {
            this.updateStorages();
        }
    }

    updateStorages() {
        if (this.props.storageStatuses) {
            const storages = [];
            const emptyStorages = [];
            for (let storageStatus of this.props.storageStatuses) {
                if (
                    this.props.lockerStatus.product &&
                    storageStatus.product &&
                    this.props.lockerStatus.product.id ===
                        storageStatus.product.id
                ) {
                    storages.push(storageStatus.storage);
                } else if (!storageStatus.product) {
                    emptyStorages.push({
                        key: storageStatus.storage.id,
                        text: window.eng.getStorageName(storageStatus.storage),
                        value: storageStatus.storage.id
                    });
                }
            }
            this.setState({ storages, emptyStorages });
            if (storages.length) {
                this.setState({ selectedStorageId: storages[0].id });
            }
        }
    }

    showInformation() {
        this.setState({ showInfoBlock: true });
    }

    closeInformation = () => {
        this.setState({ showInfoBlock: false });
    };

    onDismiss = () => {
        this.setState({ showModal: false });
    };

    async showModal() {
        // Allow manual locker editing only when no integration for site
        let result = Dialog.NO;
        console.log(this.props.site);
        if (!this.props.site.upmMillCode) {
            let dlg = new LockerDialog(
                this.props.locker,
                this.props.lockerStatus
            );
            result = await dlg.show();
        }
        if (result === Dialog.NO) {
            if (this.props.lockerStatus.count > 0) {
                this.setState({ showModal: true });
            }
        }
    }

    handleLocker = async () => {
        this.setState({ busy: true });
        await window.eng.handleLocker(
            this.props.site.id,
            this.props.vehicle ? this.props.vehicle.id : 0,
            this.props.locker.id,
            this.state.selectedStorageId
        );
        await this.props.updateSiteStatus();
        this.setState({ busy: false, showModal: false });
    };

    renderActions() {
        const className =
            this.state.storages.length > 0
                ? "ui button huge positive"
                : "ui disabled button huge positive";
        return (
            <div className="FlexHorizontal">
                <div style={{ flex: "1 1 auto" }} />
                <i
                    className="spinner circle loading icon"
                    style={{
                        display: this.state.busy ? "" : "none",
                        width: "14px",
                        height: "14px",
                        alignSelf: "center",
                        marginRight: "20px"
                    }}
                />
                <button
                    onClick={() => this.onDismiss()}
                    className="ui button huge negative"
                    disabled={this.state.busy ? true : false}
                    style={{ marginRight: "10px" }}
                >
                    Peruuta
                </button>
                <button
                    onClick={() => this.handleLocker()}
                    className={className}
                    disabled={this.state.busy ? true : false}
                >
                    Kuittaa
                </button>
            </div>
        );
    }

    renderStorages() {
        return this.state.storages.map(storage => {
            const className =
                this.state.selectedStorageId === storage.id
                    ? "ui button big RoolsGreenBG fluid"
                    : "ui button big white fluid";
            return (
                <div
                    key={storage.id}
                    onClick={() => {
                        this.setState({ selectedStorageId: storage.id });
                    }}
                    style={{ marginTop: "5px" }}
                    className={className}
                >
                    <p>Varasto: {window.eng.getStorageName(storage)}</p>
                </div>
            );
        });
    }

    handleChange = (e, { value }) => {
        this.setState({ selectedEmptyStorage: value });
    };

    async onStorageConfirm(ev) {
        ev.preventDefault();
        this.setState({ busy: true });
        await window.eng.setStorageProduct(
            this.props.site.id,
            this.state.selectedEmptyStorage,
            this.props.lockerStatus.product.id
        );
        await this.props.updateSiteStatus();
        this.setState({ busy: false });
    }

    renderRightSide = () => {
        if (this.state.storages.length > 0) {
            return (
                <div className="ui vertical buttons">
                    {this.renderStorages()}
                </div>
            );
        } else {
            const className =
                this.state.selectedEmptyStorage === 0
                    ? "ui button disabled RoolsGreenBG"
                    : "ui button RoolsGreenBG";
            return (
                <form onKeyDown={this.handleKeyPress} className="ui form">
                    <div className="field">
                        <label>Valitse varasto:</label>
                        <Dropdown
                            placeholder="Valitse varasto"
                            selection
                            fluid
                            options={this.state.emptyStorages}
                            value={this.state.selectedEmptyStorage}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <button
                            onClick={ev => this.onStorageConfirm(ev)}
                            className={className}
                            style={{ display: "inline-block" }}
                            disabled={this.state.busy ? true : false}
                        >
                            Valitse
                        </button>
                    </div>
                </form>
            );
        }
    };

    renderContent = () => {
        return (
            <div className="ui grid">
                <div className="eight wide column">
                    <p>Lokero: {this.props.locker.number}</p>
                    <p>
                        Tuote:{" "}
                        {window.eng.getProductName(
                            this.props.lockerStatus.product
                        )}
                    </p>
                </div>
                <div className="eight wide column">
                    {this.renderRightSide()}
                </div>
            </div>
        );
    };

    renderInfoBlock() {
        if (this.state.showInfoBlock && this.props.vehicle) {
            if (this.props.lockerStatus.percentage <= 80) {
                window.setTimeout(this.closeInformation, this.state.closeTime);
            }

            return (
                <div
                    onClick={() => this.showModal()}
                    className={
                        this.props.side === "TOP"
                            ? "TopInfoBlock"
                            : "BottomInfoBlock"
                    }
                    style={{
                        width: "80px",
                        height: "80px",
                        background: this.getColor()
                    }}
                >
                    <h3 className="InfoBlockText">
                        L{this.props.locker.number}
                    </h3>
                    <h4 className="InfoBlockText">
                        {window.eng.getProductName(
                            this.props.lockerStatus.product
                        )}
                    </h4>
                    <h5 className="InfoBlockText">
                        {this.props.lockerStatus.percentage
                            ? this.props.lockerStatus.percentage + "%"
                            : nbsp()}
                    </h5>
                </div>
            );
        }
    }

    renderModal() {
        if (this.state.showModal) {
            return (
                <Modal
                    title={
                        this.props.vehicle
                            ? "Ota lokerosta?"
                            : "Tyhjennä lokero?"
                    }
                    content={this.renderContent()}
                    actions={this.renderActions()}
                    onDismiss={this.onDismiss}
                />
            );
        }
    }

    render() {
        return (
            <div
                style={{
                    flex: "1 1 0",
                    maxWidth: "80px",
                    height: "65px",
                    marginRight: "2px",
                    position: "relative",
                    ...this.props.style
                }}
            >
                {this.renderModal()}
                {this.props.side === "BOTTOM" ? this.renderInfoBlock() : null}
                {this.props.side === "BOTTOM"
                    ? this.renderLockerNumber()
                    : null}
                <div
                    onClick={() =>
                        this.props.vehicle
                            ? this.showInformation()
                            : this.showModal()
                    }
                    className="LockerView"
                    style={{
                        width: "100%",
                        height: "100%",
                        background: this.getColor()
                    }}
                >
                    {this.props.vehicle ? null : (
                        <>
                            <div
                                className="LockerInfoText"
                                style={{
                                    fontWeight: "bold",
                                    whiteSpace: "nowrap"
                                }}
                            >
                                {window.eng.getProductName(
                                    this.props.lockerStatus.product
                                )}
                            </div>
                            <div className="LockerInfoText">
                                {this.props.lockerStatus.count
                                    ? this.props.lockerStatus.count
                                    : nbsp()}
                            </div>
                            <div className="LockerInfoText">
                                {this.props.lockerStatus.percentage
                                    ? this.props.lockerStatus.percentage + "%"
                                    : nbsp()}
                            </div>
                        </>
                    )}
                    {this.props.lockerStatus.vehicleAtLocker ? (
                        <div
                            style={{
                                position: "absolute",
                                left: "50%",
                                top: this.props.side === "TOP" ? "-5px" : "",
                                bottom: this.props.side === "TOP" ? "" : "-5px",
                                transform:
                                    this.props.side === "TOP"
                                        ? "translate(-50%,-100%)"
                                        : "translate(-50%,+100%)"
                            }}
                        >
                            <img
                                src={VehicleImage}
                                style={{ width: "36px" }}
                                alt="Vehicle"
                            />
                            <div
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%,-50%)",
                                    fontSize: "10px",
                                    color: "white"
                                }}
                            >
                                {this.props.lockerStatus.vehicleAtLocker.name}
                            </div>
                        </div>
                    ) : null}
                </div>
                {this.props.side === "TOP" ? this.renderLockerNumber() : null}
                {this.props.side === "TOP" ? this.renderInfoBlock() : null}
            </div>
        );
    }

    renderLockerNumber() {
        return (
            <div style={{ fontSize: "15px", fontWeight: "bold" }}>
                L{this.props.locker.number}
            </div>
        );
    }

    getColor() {
        const percentage = this.props.lockerStatus.percentage;
        let color = "";
        if (this.props.site) {
            if (isNaN(percentage)) {
                color = "rgb(224, 225, 226)";
            } else if (percentage <= this.props.site.colorLimit1) {
                color = "#21ba45"; // green
            } else if (percentage <= this.props.site.colorLimit2) {
                color = "#fbbd08"; // yellow
            } else if (percentage >= this.props.site.colorLimit2) {
                color = "#db2828"; // red
            }
        }
        return color;
    }
}
