import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import StorageModal from "./StorageModal";
import { nbsp, sortFunction } from "./cupla";

export default class StoragesTableView extends Component {
    state = {
        storageStatuses: [],
        column: null,
        direction: null,

        selectedStorageStatus: null,
        showEditModal: false,
        showAddModal: false
    };

    componentDidMount() {
        if (this.props.siteStatus) {
            this.updateStorages();
        }
    }

    componentDidUpdate(previousProps, previousState) {
        if (this.props.siteStatus !== previousProps.siteStatus) {
            this.updateStorages();
        }
    }

    updateStorages() {
        this.setState({
            storageStatuses: this.props.siteStatus.storages.slice()
        });
    }

    handleSort = clickedColumn => () => {
        const { column, storageStatuses, direction } = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                storageStatuses: storageStatuses.sort(
                    sortFunction(clickedColumn)
                ),
                direction: "ascending"
            });

            return;
        }

        this.setState({
            storageStatuses: storageStatuses.reverse(),
            direction: direction === "ascending" ? "descending" : "ascending"
        });
    };

    onEditClick = storageStatus => {
        if (window.eng.user) {
            const features = window.eng.user.features;
            if (features === "A" || features === "S" || features === "M") {
                this.setState({
                    showEditModal: true,
                    selectedStorageStatus: storageStatus
                });
            }
        }
    };

    renderStorages() {
        return this.state.storageStatuses.map(storageStatus => {
            return (
                <Table.Row
                    onClick={e => this.onEditClick(storageStatus, e)}
                    key={storageStatus.storage.id}
                >
                    <Table.Cell>V{storageStatus.storage.number}</Table.Cell>
                    <Table.Cell>{storageStatus.storage.name}</Table.Cell>
                    {this.renderProduct(storageStatus)}
                    <Table.Cell>
                        {storageStatus.count ? storageStatus.count : nbsp()}
                    </Table.Cell>
                    <Table.Cell>
                        {storageStatus.percentage
                            ? storageStatus.percentage + "%"
                            : ""}
                    </Table.Cell>
                </Table.Row>
            );
        });
    }

    renderProduct(storage) {
        if (storage.product) {
            return (
                <>
                    <Table.Cell>
                        {window.eng.getProductName(storage.product)}
                    </Table.Cell>
                    <Table.Cell>{storage.product.timberDiameter}</Table.Cell>
                    <Table.Cell>{storage.product.timberClass}</Table.Cell>
                </>
            );
        } else {
            return (
                <>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell></Table.Cell>
                </>
            );
        }
    }

    onDataUpdate = async () => {
        this.setState({ showEditModal: false, direction: null, column: null });
    };

    onModalDismiss = () => {
        this.setState({ showEditModal: false });
    };

    renderModal = () => {
        if (this.state.showEditModal) {
            return (
                <StorageModal
                    title="Varasto"
                    onDismiss={this.onModalDismiss}
                    storageStatus={this.state.selectedStorageStatus}
                    method="SAVE"
                    dataUpdate={this.onDataUpdate}
                    site={this.props.site}
                />
            );
        }
    };

    render() {
        const { column, direction } = this.state;
        if (this.state.storageStatuses.length === 0) {
            return (
                <div className="ui container">
                    <div className="ui grid">
                        <div className="ui active loader"></div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="PagePadding">
                    {this.renderModal()}
                    <Table selectable sortable collapsing celled fixed>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell
                                    style={{ fontSize: "1.2em" }}
                                    colSpan="7"
                                >
                                    Varastot
                                </Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell
                                    sorted={
                                        column === "number" ? direction : null
                                    }
                                    onClick={this.handleSort("storage.number")}
                                >
                                    Numero
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={
                                        column === "name" ? direction : null
                                    }
                                    onClick={this.handleSort("storage.name")}
                                >
                                    Varaston nimi
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={
                                        column === "name" ? direction : null
                                    }
                                    onClick={this.handleSort("product.name")}
                                >
                                    Tuotteen nimi
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={
                                        column === "timberDiameter"
                                            ? direction
                                            : null
                                    }
                                    onClick={this.handleSort(
                                        "product.timberDiameter"
                                    )}
                                >
                                    Läpimittaluokka
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={
                                        column === "timberClass"
                                            ? direction
                                            : null
                                    }
                                    onClick={this.handleSort(
                                        "product.timberClass"
                                    )}
                                >
                                    Tukkilaatu
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    style={{ minWidth: "70px" }}
                                    sorted={
                                        column === "count" ? direction : null
                                    }
                                    onClick={this.handleSort("count")}
                                >
                                    Kpl
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    style={{ minWidth: "70px" }}
                                    sorted={
                                        column === "percentage"
                                            ? direction
                                            : null
                                    }
                                    onClick={this.handleSort("percentage")}
                                >
                                    Täyttöaste
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>{this.renderStorages()}</Table.Body>
                    </Table>
                </div>
            );
        }
    }
}
