import React from "react";
import { Button, Modal } from "react-bootstrap";
import { key } from "./cupla";

export default class Dialog {
    static CANCEL = 0;
    static YES = 1;
    static NO = 2;

    icon = null;
    title = "";
    body = null;
    footer = (
        <Button
            variant="primary"
            onClick={() => this.onClickButton(Dialog.YES)}
        >
            OK
        </Button>
    );
    size = "";
    className = "";
    modalElement = null; // React element
    modalComponent = null; // React component
    resolve = null;

    async show() {
        await this.onShow();

        return new Promise(resolve => {
            this.resolve = resolve;
            this.modalElement = (
                <DialogModal
                    key={key()}
                    ref={e => (this.modalComponent = e)}
                    dialog={this}
                />
            );
            window.app.addDialog(this);
        });
    }

    finish(result) {
        if (this.modalComponent) {
            this.modalComponent.setState({ show: false });
        }
        if (this.resolve) {
            this.resolve(result ? result : Dialog.CANCEL);
            this.resolve = null;
        }
    }

    onShow() {}

    onHidden() {
        window.app.removeDialog(this);
    }

    componentDidMount() {
        // Do not remove this because called from below
    }

    componentWillUnmount() {
        // Do not remove this because called from below
    }

    setIcon(img) {
        this.icon = img;
    }

    setTitle(s) {
        this.title = s;
    }

    setBody(body) {
        this.body = body;
    }

    setFooter(footer) {
        this.footer = footer;
    }

    // size = "", "large"
    setSize(size) {
        this.size = size;
    }

    setClassName(className) {
        this.className = className;
    }

    onClickButton(result) {
        this.finish(result);
    }
}

class DialogModal extends React.Component {
    modal = null;
    state = {
        show: true
    };

    render() {
        return (
            <Modal
                ref={e => (this.modal = e)}
                show={this.state.show}
                backdrop="static"
                onHide={() => this.props.dialog.finish()}
                onExited={() => this.props.dialog.onHidden()}
                size={this.props.dialog.size === "large" ? "xl" : ""}
                dialogClassName={this.props.dialog.className}
                style={{ zIndex: 1040 }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.dialog.icon ? (
                            <img
                                src={this.props.dialog.icon}
                                alt=""
                                style={{
                                    maxHeight: "30px",
                                    margin: "-6px 10px 0 0"
                                }}
                            />
                        ) : null}
                        {this.props.dialog.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>{this.props.dialog.body}</Modal.Body>
                <Modal.Footer>{this.props.dialog.footer}</Modal.Footer>
            </Modal>
        );
    }

    componentDidMount() {
        this.props.dialog.componentDidMount();
    }

    componentWillUnmount() {
        this.props.dialog.componentWillUnmount();
    }
}
