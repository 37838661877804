import React from "react";
import { Link } from "react-router-dom";
import RoolsLogoImg from "./img/rools-logo.png";

export default class Toolbar extends React.Component {
    render() {
        if (window.eng.user) {
            return (
                <div
                    className="ui menu RoolsGreenBG inverted"
                    style={{
                        flex: "0 0 auto",
                        boxShadow: "0px 5px 11px 0px rgba(50, 50, 50, 0.3)",
                        marginBottom: "0"
                    }}
                >
                    <div
                        className="FlexHorizontal"
                        style={{ alignItems: "center" }}
                    >
                        <img
                            src={RoolsLogoImg}
                            style={{ height: "40px", margin: "0 6px 0 10px" }}
                            alt=""
                        />
                        <div
                            style={{
                                marginRight: "20px",
                                fontSize: "20px",
                                fontWeight: "bold"
                            }}
                        >
                            ROOLS
                        </div>
                    </div>
                    {!window.eng.user.isDriver() ? (
                        <>
                            <Link to="/sites">
                                <div
                                    className="NavItem item"
                                    style={{
                                        borderLeft:
                                            "1px solid rgba(255,255,255,.08)"
                                    }}
                                >
                                    <i className="map icon" />
                                    Toimipisteet
                                </div>
                            </Link>
                            <Link to="/users">
                                <div className="NavItem item">
                                    <i className="users icon" />
                                    Käyttäjät
                                </div>
                            </Link>
                        </>
                    ) : null}
                    <div className="right menu">
                        <div className="item">
                            {window.eng.user ? (
                                window.eng.user.firstName +
                                " " +
                                window.eng.user.lastName
                            ) : (
                                <div className="ui active centered inline tiny loader"></div>
                            )}
                        </div>
                        <div
                            onClick={this.props.onLogOut}
                            className="NavItem item"
                        >
                            <i
                                style={{ margin: "0px" }}
                                className="large sign-out icon"
                            />
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div />;
        }
    }
}
