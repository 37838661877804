import React from "react";
import { Table } from "semantic-ui-react";
import { Dropdown } from "semantic-ui-react";
import UserModal from "./UserModal";
import { parseValidNumber, sortFunction } from "./cupla";

export default class UsersPage extends React.Component {
    state = {
        data: [],
        column: null,
        direction: null,
        selectedUser: null,
        showEditModal: false,
        showAddModal: false,
        siteOptions: [],
        siteID: 0
    };

    constructor(props) {
        super(props);

        const siteID = parseValidNumber(
            this.props.match ? this.props.match.params.id : 0
        );
        this.state.siteID = siteID;
    }

    async componentDidMount() {
        // Sites to show in dropdown
        const sites = await window.eng.getSites();
        var siteOptions = [{ key: 0, text: "Kaikki", value: 0 }];
        for (let site of sites) {
            siteOptions.push({
                key: site.id,
                text: site.name,
                value: site.id
            });
        }
        this.setState({ siteOptions });

        await this.updateUsers(this.state.siteID);
    }

    async updateUsers(siteID) {
        const data = await window.eng.getUsers(siteID);
        this.setState({ data });
    }

    handleSort = clickedColumn => () => {
        const { column, data, direction } = this.state;

        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                data: data.sort(sortFunction(clickedColumn)),
                direction: "ascending"
            });
            return;
        }

        this.setState({
            data: data.reverse(),
            direction: direction === "ascending" ? "descending" : "ascending"
        });
    };

    onModalDismiss = () => {
        this.setState({ showAddModal: false, showEditModal: false });
    };

    onDataUpdate = async () => {
        this.setState({
            showAddModal: false,
            showEditModal: false,
            direction: null,
            column: null
        });
        await this.updateUsers(this.state.siteID);
    };

    getSiteName(siteID) {
        let opt = this.state.siteOptions.find(opt => opt.key === siteID);
        return opt ? opt.text : "";
    }

    renderModal = () => {
        if (this.state.showAddModal) {
            return (
                <UserModal
                    title={
                        "Uusi Käyttäjä" +
                        (this.state.siteID
                            ? " (" + this.getSiteName(this.state.siteID) + ")"
                            : "")
                    }
                    onDismiss={this.onModalDismiss}
                    method="ADD"
                    site={this.state.siteID}
                    dataUpdate={this.onDataUpdate}
                />
            );
        }
        if (this.state.showEditModal) {
            return (
                <UserModal
                    title="Käyttäjä"
                    onDismiss={this.onModalDismiss}
                    user={this.state.selectedUser}
                    method="SAVE"
                    dataUpdate={this.onDataUpdate}
                />
            );
        }
    };

    onAddClick = e => {
        this.setState({ showAddModal: true });
    };

    onEditClick = user => {
        this.setState({ showEditModal: true, selectedUser: user });
    };

    renderAddButton() {
        if (window.eng.user) {
            const features = window.eng.user.features;
            if (features === "A" || features === "S") {
                return (
                    <button
                        onClick={this.onAddClick}
                        className="ui active button RoolsGreenBG right floated"
                    >
                        <i className="plus circle icon" />
                        Lisää Käyttäjä
                    </button>
                );
            }
        }
    }

    renderUsers() {
        return this.state.data.map(user => {
            return (
                <Table.Row
                    onClick={e => this.onEditClick(user, e)}
                    key={user.id}
                >
                    <Table.Cell>
                        {user.firstName} {user.lastName}
                    </Table.Cell>
                    <Table.Cell>{user.email}</Table.Cell>
                    <Table.Cell>{this.renderFeatures(user)}</Table.Cell>
                </Table.Row>
            );
        });
    }

    renderFeatures = user => {
        const features = user.features;
        if (features === "A") return <>Ylläpitäjä</>;
        else if (features === "S") return <>Super</>;
        else if (features === "M") return <>Pääkäyttäjä</>;
        else if (features === "D") return <>Kuljettaja</>;
        else return <></>;
    };

    onSiteChanged(ev, data) {
        let siteID = data.value;
        this.setState({ siteID });
        this.updateUsers(siteID);
    }

    render() {
        const { column, direction } = this.state;
        return (
            <div className="PagePadding">
                {this.renderModal()}

                <div
                    className="FlexHorizontal"
                    style={{ alignItems: "center" }}
                >
                    <h2 style={{ marginRight: "100px", marginBottom: 0 }}>
                        Käyttäjät
                    </h2>
                    <div className="nine wide column">
                        <Dropdown
                            placeholder="Valitse toimipiste"
                            selection
                            defaultValue={this.state.siteID}
                            options={this.state.siteOptions}
                            onChange={(ev, data) =>
                                this.onSiteChanged(ev, data)
                            }
                        />
                    </div>
                    <div style={{ flex: "1 1 auto" }} />
                    {this.renderAddButton()}
                </div>

                <Table selectable sortable collapsing celled fixed>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell
                                sorted={
                                    column === "firstName" ? direction : null
                                }
                                onClick={this.handleSort("firstName")}
                            >
                                Nimi
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={column === "email" ? direction : null}
                                onClick={this.handleSort("email")}
                            >
                                Email
                            </Table.HeaderCell>
                            <Table.HeaderCell
                                sorted={
                                    column === "features" ? direction : null
                                }
                                onClick={this.handleSort("features")}
                            >
                                Ominaisuudet
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>{this.renderUsers()}</Table.Body>
                </Table>
            </div>
        );
    }
}
