import React from "react";
import ReactDOM from "react-dom";
import Modal from "./Modal";

export default class CustomerModal extends React.Component {
    state = {
        id: 0,
        name: "",
        showConfirmation: false,
        siteData: []
    };

    async componentDidMount() {
        if (this.props.customer !== undefined) {
            this.setState({ id: this.props.customer.id });

            if (this.props.customer.name !== undefined)
                this.setState({ name: this.props.customer.name });
        }

        if (this.props.method === "SAVE") {
            const sites = await window.eng.getSites();

            var siteData = [];
            for (var i = 1; i < sites.length; i++) {
                if (sites[i].customer !== null) {
                    if (sites[i].customer.id === this.props.customer.id) {
                        siteData.push(sites[i]);
                    }
                }
            }
            this.setState({ siteData });
        }
        document.addEventListener("keydown", this.escFunction, false);
    }

    escFunction = event => {
        if (event.keyCode === 27) this.props.onDismiss();
    };

    renderContent() {
        return (
            <form className="ui form">
                <div className="field">
                    <label>Nimi:</label>
                    <input
                        onChange={e => this.setState({ name: e.target.value })}
                        type="text"
                        value={this.state.name}
                    />
                </div>
            </form>
        );
    }

    // Delete confirmation modal stuff //
    onDismiss = () => {
        this.setState({ showConfirmation: false });
    };

    confirmContent() {
        return <>Haluatko varmasti poistaa asiakkaan?</>;
    }

    confirmActions() {
        return (
            <>
                <button
                    onClick={this.onDeleteConfirm}
                    className="ui button red"
                >
                    Kyllä
                </button>
                <button onClick={this.onDismiss} className="ui button">
                    Peruuta
                </button>
            </>
        );
    }

    onDeleteConfirm = async e => {
        await window.eng.deleteCustomer(this.state.id);
        this.props.dataUpdate();
        await this.props.onDismiss();
    };

    // Button click calls and API calls.
    onDeleteClick = e => {
        this.setState({ showConfirmation: true });
    };

    deleteCustomer = async id => {
        //await deleteUser(id); console.log("deleteUser");
        await this.props.onDismiss();
    };

    onSaveClick = async e => {
        await window.eng.setCustomer({
            id: this.state.id,
            name: this.state.name
        });
        this.props.dataUpdate();
        await this.props.onDismiss();
    };

    // Render bottom part of the modal (buttons).
    renderActions() {
        return (
            <>
                {this.renderDelete()}
                <button onClick={this.props.onDismiss} className="ui button">
                    Peruuta
                </button>
                <button
                    onClick={this.onSaveClick}
                    className="ui button RoolsGreenBG"
                >
                    Tallenna
                </button>
            </>
        );
    }

    renderDelete() {
        if (this.props.method === "SAVE" && this.state.siteData.length === 0) {
            return (
                <>
                    <button
                        onClick={this.onDeleteClick}
                        className="ui button red floatLeft"
                    >
                        Poista
                    </button>
                </>
            );
        }
    }

    renderConfirmationModal = () => {
        if (this.state.showConfirmation) {
            return (
                <Modal
                    onDismiss={this.onDismiss}
                    title=""
                    content={this.confirmContent()}
                    actions={this.confirmActions()}
                />
            );
        }
    };

    // Main render.
    render() {
        return ReactDOM.createPortal(
            <div
                onClick={this.props.onDismiss}
                className="ui dimmer modals visible active"
            >
                <div
                    style={{ position: "relative" }}
                    className="ui standard modal visible active"
                    onClick={e => e.stopPropagation()}
                >
                    {this.renderConfirmationModal()}
                    <div className="header">{this.props.title}</div>
                    <div className="content">{this.renderContent()}</div>
                    <div className="actions">{this.renderActions()}</div>
                </div>
            </div>,
            document.querySelector("#modal")
        );
    }
}
